import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Divider } from 'wix-ui-tpa';
import { scroller } from 'react-scroll';
import { Modals } from '../../../../core/constants';
import { SetCheckoutStepPayload } from '../../../../state/checkout/checkout.actions.types';
import { CloseModalPayload, OpenModalPayload } from '../../../../state/session/session.actions.types';
import ContactInformation from '../ContactInformation';
import dataHooks from '../../data-hooks';
import { calculateTopValue } from '../Scroller/scrollingUtils';
import Cart from '../Cart';
import styles from './CheckoutFlow.scss';
import AddressInformation from '../AddressInformation';
import CheckoutPayments from '../CheckoutPayments';
import Sticky, { getHeaderElement, isElementInPosition } from '../Sticky/Sticky';
import CheckoutReview from '../CheckoutReview';
import Text from '../../core-components/Text';
import { TEXT_BUTTON_PRIORITY, TextButton } from '../TextButton';
import { Contact, DispatchType, Address, calcEarnedPoints } from '@wix/restaurants-client-logic';
import { CheckoutStep } from '../../../../core/types/Checkout';
import CheckoutSubmit from '../CheckoutSubmit';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import Spinner from '../Spinner';
import MemberLoginCTA from '../MemberLoginCTA';
import { Address as MembersAddress } from '@wix/ambassador-addresses-web/types';
import _ from 'lodash';
import AddressInformationView from '../AddressInformationView';
import { EarningRule } from '@wix/ambassador-loyalty-calculator/src/types';
import stylesParams from '../../stylesParams';
import { useStyles } from '@wix/tpa-settings/react';
import classNames from 'classnames';
import MediaQueryWrapper from '../MediaQueryWrapper/MediaQueryWrapper';
import { checkoutEdit, checkoutBackToMenu, logInRequest } from '@wix/bi-logger-olo-client/v2';

export interface CheckoutFlowProps {
  dispatchType: DispatchType;
  isMobile?: boolean;
  siteIsTemplate?: boolean;
  shouldGoBack: boolean;
  step: CheckoutStep;
  setStep: (payload: SetCheckoutStepPayload) => void;
  closeModal: (payload: CloseModalPayload) => void;
  initialContact: Contact;
  isMemberContactLoading: boolean;
  isUserLoggedIn: boolean;
  loginMember: any;
  openModal: (payload: OpenModalPayload) => void;
  hasMembersAreaIntegration?: boolean;
  loggedInUserEmail?: string;
  isMAInstalled?: boolean;
  savedAddresses: MembersAddress[];
  address: Address;
  selectedAddressOption?: Address;
  centsAmount: number;
  pointsRequired: number;
  currencyValue: number;
  customPointsName?: string;
  pointsBalance?: number;
  isLoyaltyEarnActive?: boolean;
  isMultiLocation?: boolean;
  earningRules: EarningRule[];
  submitOrder: Function;
}

const orderedSteps: CheckoutStep[] = ['address-information', 'contact-information', 'payments', 'review'];

function isAfter(currentStep: CheckoutStep, compareTo: CheckoutStep) {
  return orderedSteps.indexOf(currentStep) > orderedSteps.indexOf(compareTo);
}

function isBefore(currentStep: CheckoutStep, compareTo: CheckoutStep) {
  return orderedSteps.indexOf(currentStep) < orderedSteps.indexOf(compareTo);
}

export const getScrollOptions = () => {
  return {
    smooth: 'easeInOutCubic',
    duration: 200,
    offset: -calculateTopValue(0) - 30,
  };
};

function useEnvironmentSafe() {
  try {
    return useEnvironment();
  } catch (e) {
    return { isPreview: false };
  }
}

const CheckoutFlow: React.FC<CheckoutFlowProps> = ({
  isMobile,
  shouldGoBack,
  step,
  setStep,
  closeModal,
  initialContact,
  isUserLoggedIn,
  loginMember,
  hasMembersAreaIntegration,
  openModal,
  isMemberContactLoading,
  loggedInUserEmail,
  isMAInstalled,
  dispatchType,
  savedAddresses,
  address,
  selectedAddressOption,
  centsAmount,
  customPointsName,
  pointsBalance,
  isLoyaltyEarnActive,
  isMultiLocation,
  earningRules,
  siteIsTemplate,
  submitOrder,
}) => {
  const { t } = useTranslation();
  const goToAddressInformation = () => {
    setStep({ step: 'address-information' });
  };
  const goToContactInformation = () => {
    setStep({ step: 'contact-information' });
  };
  const goToPayments = () => {
    setStep({ step: 'payments' });
  };
  const goToReview = () => setStep({ step: 'review' });
  const onSubmit = (skipReviewStep?: boolean) => (skipReviewStep ? submitOrder() : goToReview());
  const biLogger = useBi();
  const history = useHistory();
  const { isPreview } = useEnvironmentSafe();
  const isMembersAddressEnabled = isMAInstalled && isUserLoggedIn && hasMembersAreaIntegration;
  const hasSavedAddresses = !_.isEmpty(savedAddresses);
  const addressForMinPrice = hasSavedAddresses && isMembersAddressEnabled ? address : selectedAddressOption;

  const tpaStyles = useStyles();
  const isEditorX = tpaStyles.get(stylesParams.responsive);
  const headerElement = getHeaderElement(isEditorX);
  const isHeaderSticky = isElementInPosition('sticky', headerElement);

  const isEdgeCaseOfEditorXandPreviewAndStickyHeader: boolean = isPreview && isEditorX && isHeaderSticky;
  const primaryContentStyle = isEdgeCaseOfEditorXandPreviewAndStickyHeader
    ? styles.primaryContentEdgecaseEditorx
    : styles.primaryContent;

  const resetCheckoutStep = () => setStep({ step: 'address-information' });
  useEffect(() => {
    if (!isPreview && shouldGoBack) {
      history.replace('/');
    }
  }, [shouldGoBack, history, isPreview]);
  const checkoutReviewCollapsed = isBefore(step, 'review');

  useEffect(() => {
    closeModal({ modal: Modals.CART_MODAL });
    scroller.scrollTo('checkout-flow', getScrollOptions());
  }, [closeModal]);

  const sendBiEditStep = (checkoutStep: CheckoutStep) => {
    biLogger.report(
      checkoutEdit({
        type: checkoutStep,
      }),
    );
  };

  const titleId = 'restaurants.checkout-flow.title';
  const AddressInformationComponent = isMultiLocation ? AddressInformationView : AddressInformation;

  const shouldMemberCTA = hasMembersAreaIntegration && isMAInstalled;
  return (
    <div
      id="checkout-flow"
      className={isEditorX ? classNames([styles.wrapperX]) : `${styles.wrapper} ${isMobile && styles.mobile}`}
      data-hook={dataHooks.checkoutFlow}
    >
      <div className={styles.title}>
        <Text id={titleId} typography="header-l">
          {t('checkout_main_checkoutsection_title')}
        </Text>
        <Link
          to="/"
          onClick={() => {
            biLogger.report(checkoutBackToMenu({}));
            resetCheckoutStep();
            window.scrollTo(0, 0);
          }}
          tabIndex={-1}
        >
          <TextButton priority={TEXT_BUTTON_PRIORITY.primary} data-hook={dataHooks.checkoutFlowBackToMenu}>
            <Text typography="p2-m-colorless">{t('checkout_main_back_to_menu')}</Text>
          </TextButton>
        </Link>
      </div>

      <div className={primaryContentStyle}>
        {shouldMemberCTA && (
          <div className={styles.primaryContent}>
            <MemberLoginCTA
              isMobile={isMobile}
              isUserLoggedIn={isUserLoggedIn}
              onLoginClick={() => {
                loginMember();
                biLogger.report(logInRequest({ origin: 'checkout-banner' }));
              }}
              userEmail={loggedInUserEmail}
              onLogoutClick={() => openModal({ modal: Modals.LOGOUT_MODAL })}
              pointsToBeEarned={calcEarnedPoints({ rules: earningRules, moneyAmountInCents: centsAmount })}
              customPointsName={customPointsName}
              pointsBalance={pointsBalance}
              isLoyaltyEarnActive={isLoyaltyEarnActive}
              className={isMultiLocation && styles.memberLoginCta}
            />
          </div>
        )}
        <AddressInformationComponent
          index={String(orderedSteps.indexOf('address-information') + 1)}
          onSubmit={goToContactInformation}
          done={isAfter(step, 'address-information')}
          collapsed={isBefore(step, 'address-information')}
          onEdit={() => {
            sendBiEditStep('address-information');
            goToAddressInformation();
          }}
          describedby={titleId}
        />
        <div className={styles.dividerWrapper}>
          <Divider className={styles.divider} />
        </div>
        {isMemberContactLoading && isBefore(step, 'contact-information') ? (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        ) : (
          <ContactInformation
            index={String(orderedSteps.indexOf('contact-information') + 1)}
            onSubmit={goToPayments}
            done={isAfter(step, 'contact-information')}
            collapsed={isBefore(step, 'contact-information')}
            onEdit={() => {
              sendBiEditStep('contact-information');
              goToContactInformation();
            }}
            initialContact={initialContact}
            describedby={titleId}
            siteIsTemplate={siteIsTemplate}
          />
        )}
        <div className={styles.dividerWrapper}>
          <Divider className={styles.divider} />
        </div>
        <CheckoutPayments
          index={String(orderedSteps.indexOf('payments') + 1)}
          onSubmit={onSubmit}
          done={isAfter(step, 'payments')}
          siteIsTemplate={siteIsTemplate}
          collapsed={isBefore(step, 'payments')}
          onEdit={() => {
            sendBiEditStep('payments');
            goToPayments();
          }}
          step={step}
          describedby={titleId}
        />
        <div className={styles.dividerWrapper}>
          <Divider className={styles.divider} />
        </div>
        <CheckoutReview
          index={String(orderedSteps.indexOf('review') + 1)}
          collapsed={checkoutReviewCollapsed}
          describedby={titleId}
          isMobile={!!isMobile}
        />
        {isEditorX ? (
          <MediaQueryWrapper mobileOnly>
            <div data-hook={dataHooks.checkoutFlowCartWrapper}>
              <Cart className={styles.cartWrapperMobile} shortVersion displayTitle isCartImmutable />
            </div>
          </MediaQueryWrapper>
        ) : (
          isMobile && (
            <div data-hook={dataHooks.checkoutFlowCartWrapper}>
              <Cart className={styles.cartWrapperMobile} shortVersion displayTitle isCartImmutable />
            </div>
          )
        )}
        {!checkoutReviewCollapsed && <CheckoutSubmit />}
      </div>

      {isEditorX ? (
        <MediaQueryWrapper desktopOnly className={styles.stickyEditorx}>
          <div className={styles.secondaryContent}>
            <Sticky offset={24}>
              <div className={styles.cartWrapper} data-hook={dataHooks.checkoutFlowCartWrapper}>
                <Cart
                  hideMinOrderPrice={!addressForMinPrice && dispatchType === 'delivery'}
                  displayTitle
                  isCartImmutable
                  selectedAddressOption={addressForMinPrice}
                />
              </div>
            </Sticky>
          </div>
        </MediaQueryWrapper>
      ) : (
        !isMobile && (
          <div className={styles.secondaryContent}>
            <Sticky offset={24}>
              <div className={styles.cartWrapper} data-hook={dataHooks.checkoutFlowCartWrapper}>
                <Cart
                  hideMinOrderPrice={!addressForMinPrice && dispatchType === 'delivery'}
                  displayTitle
                  isCartImmutable
                  selectedAddressOption={addressForMinPrice}
                />
              </div>
            </Sticky>
          </div>
        )
      )}
    </div>
  );
};

CheckoutFlow.displayName = 'CheckoutFlow';

export default CheckoutFlow;
