import React from 'react';
import { Spinner as TpaSpinner, SpinnerProps as TpaSpinnerProps } from 'wix-ui-tpa';
import { st, classes } from './Spinner.st.css';

export interface SpinnerProps extends TpaSpinnerProps {}

const Spinner: React.FC<SpinnerProps> = (props) => {
  return <TpaSpinner {...props} className={st(classes.root, {}, '')} />;
};

Spinner.displayName = 'Spinner';

export default Spinner;
