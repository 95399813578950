import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { isAvailableOn } from 'availability';
import { useExperiments, useTranslation, useBi } from '@wix/yoshi-flow-editor';
import Dropdown from '../Dropdown';
import Text from '../../core-components/Text';
import dataHooks from '../../data-hooks';
import { TimingOption } from '../DispatchSettings/DispatchSettings';
import styles from './DispatchTimeSelector.scss';
import {
  BusinessNotifications,
  DispatchInfo,
  Restaurant,
  VirtualDispatchType,
  Address,
  isAvailabilityIteratorAvailable,
  DispatchTypeAvailability,
} from '@wix/restaurants-client-logic';
import {
  getAsapText,
  getDishPrepareTime,
  getDispatchTimeSelectorDetails,
  getTimeDropdownOptions,
  getTimeOptionsWithNonSelectableBusyBlocks,
} from './DispatchTimeSelector.helper';
import { BusinessNotificationDetails } from '../../../../state/selectors/businessNotificationSelector';
import { getDispatchTypeFromVirtual } from '../../../../core/logic/dispatchLogic';
import { PartialLocation } from '../../../../core/oloApi';
import { SetErrorVisibilityPayload } from '../../../../state/addressInformationForm/addressForm.actions.types';
import useOrderPacingBusyBlocks from '../../../../core/hooks/useOrderPacingBusyBlock';
import { dispatchSettingsClick } from '@wix/bi-logger-olo-client/v2';

export interface DispatchTimeProps {
  restaurant: Restaurant | PartialLocation;
  dispatchType: VirtualDispatchType;
  timingOption?: TimingOption;
  dispatchTime?: number;
  dispatchTimeCache?: number;
  onChange: (changes: { timingOption: TimingOption; selectedDateTime?: number }) => void;
  setErrorVisibility: (payload: SetErrorVisibilityPayload) => void;
  isMobile?: boolean;
  error?: string;
  idealDeliveryArea?: DispatchInfo;
  businessNotification?: BusinessNotificationDetails;
  isRTL?: boolean;
  isModal?: boolean;
  isMl?: boolean;
  address?: Address;
  supportedDispatchTypesV2: Partial<Record<VirtualDispatchType, DispatchTypeAvailability>>;
  orderPacingLevel?: number;
  locationId?: string;
}

export const DispatchTime = ({
  timingOption = 'asap',
  dispatchTime,
  restaurant,
  dispatchType,
  onChange,
  setErrorVisibility,
  isMobile,
  error,
  idealDeliveryArea,
  businessNotification,
  isRTL,
  isModal,
  isMl,
  address,
  supportedDispatchTypesV2,
  orderPacingLevel,
  locationId,
  dispatchTimeCache,
}: DispatchTimeProps) => {
  const { t } = useTranslation();
  const { timezone, deliveryInfos } = restaurant;
  const { experiments } = useExperiments();
  const biLogger = useBi();
  const asapText = getAsapText({
    timezone,
    deliveryInfos,
    dispatchType,
    locale: restaurant.locale,
    t,
    idealDeliveryArea,
    orderPacingLevel,
  });

  const {
    dateError,
    timeError,
    dateOptions,
    timeOptions,
    finalAvailability,
    finalAvailabilityIteratorFactory,
    selectedDate,
    dishPrepareTime,
  } = getDispatchTimeSelectorDetails({
    timingOption,
    dispatchTime,
    restaurant,
    dispatchType,
    t,
    error,
    idealDeliveryArea,
    address,
  });

  const isOrderPacingEnabled = !experiments.enabled('specs.restaurants.disable-order-pacing');
  const busyBlocks = useOrderPacingBusyBlocks({
    isEnabled: isOrderPacingEnabled,
    locationId,
    fromTimestamp: timingOption === 'later' ? selectedDate : null,
    orderFulfillmentTimeInMinutes: dishPrepareTime,
  });

  const timeOptionsWithBusyBlocks = getTimeOptionsWithNonSelectableBusyBlocks(timeOptions, busyBlocks);

  // update form error visibility if we have date or time error
  useEffect(() => {
    if (dateError || timeError) {
      setErrorVisibility({ error: 'timingOption', value: true });
    } else {
      setErrorVisibility({ error: 'timingOption', value: false });
    }
  }, [dateError, timeError, setErrorVisibility]);

  const isDeliveryType = dispatchType === 'delivery';

  const earliestTimeOptions = useMemo(
    () =>
      dateOptions[0]?.id
        ? getTimeDropdownOptions(
            finalAvailability,
            timezone,
            parseInt(dateOptions[0].id, 10),
            restaurant.orders.future.delayMins,
            getDishPrepareTime(restaurant, getDispatchTypeFromVirtual(dispatchType)),
            restaurant.locale,
            finalAvailabilityIteratorFactory,
          )
        : [],
    [dateOptions, dispatchType, finalAvailability, finalAvailabilityIteratorFactory, restaurant, timezone],
  );

  const now = moment().tz(timezone);

  let isAvailableNow = finalAvailabilityIteratorFactory
    ? isAvailabilityIteratorAvailable(finalAvailabilityIteratorFactory(now))
    : isAvailableOn(finalAvailability, now);
  let isOnlyAsap = (!isMl || isAvailableNow) && restaurant.orders.future.disabled && !restaurant.orders.asap.disabled;
  let isOnlyFutureOrders =
    isDeliveryType && !isMl
      ? businessNotification?.notification === BusinessNotifications.OnlyFutureOrders
      : !restaurant.orders.future.disabled && earliestTimeOptions[0]?.id && !isAvailableNow;

  if (experiments.enabled('specs.restaurants.olo-client-dtl-v2')) {
    const willBeAvailable = Boolean(supportedDispatchTypesV2[dispatchType]?.willBeAvailable);

    isAvailableNow = Boolean(supportedDispatchTypesV2[dispatchType]?.isAvailable);
    isOnlyAsap = isAvailableNow && !supportedDispatchTypesV2[dispatchType]?.willBeAvailable;
    isOnlyFutureOrders = !isAvailableNow && willBeAvailable;
  }

  if (isOnlyFutureOrders) {
    timingOption = 'later';
  } else if (isOnlyAsap) {
    timingOption = 'asap';
  }

  const shouldChooseDateAndTime =
    !restaurant.orders.future.disabled &&
    (timingOption === 'later' || restaurant.orders.asap.disabled || isOnlyFutureOrders);

  const timingOptionError = timingOption === 'asap' ? error : undefined;

  const firstAvailableTimeOption = timeOptionsWithBusyBlocks?.find((timeOption) => timeOption.isSelectable);

  useEffect(() => {
    const getSelectedDateTime = () => {
      if (timingOption !== 'later') {
        return undefined;
      }
      const earliestTime = parseInt(_.get(earliestTimeOptions, '[0].id', '0'), 10);
      return dispatchTimeCache || earliestTime;
    };

    onChange({ timingOption, selectedDateTime: getSelectedDateTime() });
    setErrorVisibility({ error: 'timingOption', value: false });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchType, timingOption]);

  useEffect(() => {
    if (isOrderPacingEnabled) {
      if (!firstAvailableTimeOption?.id || !dispatchTime) {
        return;
      }
      const isSelectedDispatchTimeAvailable = timeOptionsWithBusyBlocks.find(
        (block) => block.id === String(dispatchTime),
      )?.isSelectable;
      if (!isSelectedDispatchTimeAvailable) {
        onChange({ timingOption, selectedDateTime: Number(firstAvailableTimeOption.id) });
      }
    }
  }, [locationId, busyBlocks]);

  if (dateOptions.length === 0 && isModal) {
    return null;
  }

  const labelText = isDeliveryType ? t('checkout_main_delivery_time') : t('checkout_main_pickup_time');
  const shouldDisplayTimingOptionsDropdown =
    !isOnlyFutureOrders && !restaurant.orders.future.disabled && !restaurant.orders.asap.disabled;

  const getTimeSelectionInitialSelectedId = () => {
    if (isOrderPacingEnabled) {
      const isSelectedDispatchTimeAvailable =
        dispatchTime && timeOptionsWithBusyBlocks.find((block) => block.id === String(dispatchTime))?.isSelectable;
      return isSelectedDispatchTimeAvailable ? String(dispatchTime) : firstAvailableTimeOption?.id;
    }

    return dispatchTime ? String(dispatchTime) : String(dispatchTimeCache || '') || timeOptions[0]?.id;
  };

  return (
    <div data-hook={dataHooks.dispatchTimeSelector} className={styles.wrapper}>
      {!shouldDisplayTimingOptionsDropdown && (
        <Text typography="p2-s" className={styles.label}>
          {labelText}
        </Text>
      )}
      {isOnlyAsap && (
        <Text typography="p2-m" className={styles.label} data-hook={dataHooks.asapTextWithoutDropdown}>
          {asapText}
        </Text>
      )}
      {shouldDisplayTimingOptionsDropdown && (
        <Dropdown
          label={labelText}
          key={asapText}
          className={styles.timing}
          upgrade
          data-hook={dataHooks.dispatchSummaryTimingOption}
          options={[
            { id: 'asap', value: asapText, isSelectable: true },
            { id: 'later', value: t('checkout_main_specific_time'), isSelectable: true },
          ]}
          onChange={({ id }) => {
            const newTimingOption: TimingOption = id as TimingOption;
            onChange({
              timingOption: newTimingOption,
              selectedDateTime:
                newTimingOption === 'later'
                  ? dispatchTime || (timeOptions.length && Number(firstAvailableTimeOption?.id))
                  : undefined,
            });
            biLogger.report(
              dispatchSettingsClick({
                clickName: 'time-type',
                clickValue: newTimingOption,
              }),
            );
          }}
          initialSelectedId={timingOption}
          error={Boolean(timingOptionError)}
          errorMessage={timingOptionError}
          mobileNativeSelect
        />
      )}
      {shouldChooseDateAndTime && (
        <React.Fragment>
          <div className={`${styles.dateTimeWrapper} ${isMobile && styles.mobile}`}>
            <div id="date-selector" className={styles.date}>
              <Dropdown
                label={t('checkout_main_delivery_day')}
                upgrade
                data-hook={dataHooks.dispatchSummaryTimingDate}
                options={dateOptions}
                onChange={(selectedOption) => {
                  const selectedTime = timeOptions.find((to) => to.id === String(dispatchTime));
                  const newTimeOptions = getTimeDropdownOptions(
                    finalAvailability,
                    restaurant.timezone,
                    Number(selectedOption.id),
                    restaurant.orders.future.delayMins,
                    getDishPrepareTime(restaurant, getDispatchTypeFromVirtual(dispatchType)),
                    restaurant.locale,
                    finalAvailabilityIteratorFactory,
                  );
                  const newSelectedDateTime =
                    (selectedTime && Number(newTimeOptions.find((to) => to.value === selectedTime.value)?.id)) ||
                    Number(newTimeOptions[0].id);

                  onChange({
                    timingOption,
                    selectedDateTime: newSelectedDateTime || Number(selectedOption.id),
                  });

                  biLogger.report(
                    dispatchSettingsClick({
                      clickName: 'day',
                      clickValue: String(newSelectedDateTime || selectedOption.id),
                    }),
                  );
                }}
                initialSelectedId={String(selectedDate)}
                error={Boolean(dateError)}
                errorMessage={dateError}
                mobileNativeSelect
                appendTo={isModal ? 'scrollParent' : undefined}
              />
            </div>
            <div className={`${styles.spacer} ${isMobile && styles.mobile}`} />
            <div id="time-selector" className={styles.time}>
              <Dropdown
                label={t('checkout_main_delivery_hour')}
                data-hook={dataHooks.dispatchSummaryTimingTime}
                options={isOrderPacingEnabled ? timeOptionsWithBusyBlocks : timeOptions}
                onChange={(selectedOption) => {
                  onChange({ timingOption, selectedDateTime: Number(selectedOption.id) });
                  biLogger.report(
                    dispatchSettingsClick({
                      clickName: 'hour',
                      clickValue: selectedOption.id,
                    }),
                  );
                }}
                initialSelectedId={getTimeSelectionInitialSelectedId()}
                error={Boolean(timeError)}
                errorMessage={timeError}
                disabled={Boolean(!timeError && dateError)}
                errorTooltipPlacement={isRTL ? 'top-start' : 'top-end'}
                appendTo={isModal ? 'scrollParent' : undefined}
                mobileNativeSelect
                upgrade
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

DispatchTime.displayName = 'DispatchTime';

export default React.memo(DispatchTime);
