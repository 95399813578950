import { RestaurantsOrderPacingPacer } from '@wix/ambassador-restaurants-order-pacing-pacer/http';
import { BusyBlock } from '@wix/ambassador-restaurants-order-pacing-pacer/types';
import { IFedOpsLogger } from '@wix/native-components-infra/dist/src/types/types';
import { convertOrderPacingDelayToLevel, PERIODS_IN_MILLISECONDS } from '@wix/restaurants-client-logic';

export async function fetchOrderPacingCurrentLevel(
  signedInstance: string,
  locationId: string | undefined,
  fedopsLogger: IFedOpsLogger,
): Promise<number> {
  const orderPacingPacer = RestaurantsOrderPacingPacer('/_api/order-pacing-pacer/').OrderPacingService()({
    Authorization: signedInstance,
  });

  fedopsLogger.interactionStarted('get-order-pacing-delay');
  try {
    const currentDelayResponse = await orderPacingPacer.getCurrentDelay({ locationId });
    const currentDelay = currentDelayResponse?.delay?.durationInMinutes;
    const currentLevel = convertOrderPacingDelayToLevel(currentDelay);

    fedopsLogger.interactionEnded('get-order-pacing-delay');
    return currentLevel;
  } catch (e) {
    console.error('unable to get the current order pace:', e);
    return 0;
  }
}

interface fetchOrderPacingBusyBlocksParams {
  signedInstance: string;
  locationId: string | undefined;
  fromTimestamp: number;
  orderFulfillmentTimeInMinutes: number;
  fedopsLogger: Pick<IFedOpsLogger, 'interactionStarted' | 'interactionEnded'>;
}

export async function fetchOrderPacingBusyBlocks({
  signedInstance,
  locationId,
  fromTimestamp,
  orderFulfillmentTimeInMinutes,
  fedopsLogger,
}: fetchOrderPacingBusyBlocksParams): Promise<BusyBlock[]> {
  const orderPacingPacer = RestaurantsOrderPacingPacer('/_api/order-pacing-pacer/').OrderPacingService()({
    Authorization: signedInstance,
  });

  fedopsLogger.interactionStarted('get-order-pacing-busy-blocks');

  try {
    const listBusyBlocksResponse = await orderPacingPacer.listBusyBlocks({
      locationId,
      from: new Date(fromTimestamp),
      until: new Date(fromTimestamp + PERIODS_IN_MILLISECONDS.day),
      orderFulfillmentTimeInMinutes,
    });
    const busyBlocks = listBusyBlocksResponse?.busyBlocks || [];

    fedopsLogger.interactionEnded('get-order-pacing-busy-blocks');
    return busyBlocks;
  } catch (e) {
    console.error(`unable to get the current order pacing busy blocks for time ${fromTimestamp}`, e);
    return [];
  }
}
