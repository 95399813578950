import TermsAndConditionsModal from './TermsAndConditionsModal';
import { AppState } from '../../../../state/createStore';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getOrderPolicy } from '../../../../core/logic/policyLogic';

function mapStateToProps(state: AppState) {
  return {
    orderPolicy: getOrderPolicy(state.session.restaurant),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditionsModal);
