import NotificationBar from './NotificationBar';
import { AppState } from '../../../../state/createStore';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  businessNotificationSelector,
  businessNotificationSelectorByDispatchTime,
} from '../../../../state/selectors/businessNotificationSelector';
import { selectLocationsWithNotification } from '../../../../state/selectors/locationsSelector';
import { getVirtualDispatchTypeFromDispatch } from '../../../../core/logic/dispatchLogic';

function mapStateToProps(state: AppState, ownProps: any) {
  const { ShouldBeRenderedByDispatchTime } = ownProps;
  const businessNotification = ShouldBeRenderedByDispatchTime
    ? businessNotificationSelectorByDispatchTime(state)
    : businessNotificationSelector(state);

  return {
    ...ownProps,
    notification: businessNotification?.notification,
    locations: selectLocationsWithNotification(state),
    comment: businessNotification?.comment,
    dispatchType: getVirtualDispatchTypeFromDispatch(state.checkout.dispatch),
    lockedDineInLocation: state.session.lockedDineInLocation,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBar);
