import { AppState } from '../createStore';
import { createSelector } from 'reselect';

export const getCalculatedFeesParamsSelector = createSelector(
  [
    (state: AppState) => state.session.restaurant.locale,
    (state: AppState) => state.session.restaurant.currency,
    (state: AppState) => state.session.restaurantLocations,
    (state: AppState) => state.platformParams.signedInstance,
    (state: AppState) => state.platformParams.isMobile,
    (state: AppState) => state.session.hasServiceFeesRules,
  ],
  (locale, currency, restaurantLocations, signedInstance, isMobile, hasServiceFeesRules) => {
    return { locale, currency, restaurantLocations, signedInstance, isMobile, hasServiceFeesRules };
  },
);
