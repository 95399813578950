import { AppState } from '../../../../state/createStore';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { logoutMember } from '../../../../state/checkout/checkout.actions';
import LogoutModal from './LogoutModal';

function mapStateToProps(state: AppState, ...ownProps: any) {
  const email = state.checkout.membersApiContact?.emails ? state.checkout.membersApiContact.emails[0] : undefined;
  return {
    email,
    isMobile: state.platformParams.isMobile,
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      onLogoutClick: logoutMember,
    },
    dispatch,
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(LogoutModal);
