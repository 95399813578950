import { AppState } from '../createStore';
import { createSelector } from 'reselect';
import { cartSummarySelector } from './cartSummarySelector';
import { CalculatedFee } from '@wix/ambassador-service-fees-rules/types';
import { getRestLocationId } from '../../core/calcServiceFees';

export type ServiceFeesBIEventParams = {
  feeIds?: string;
  feesCount?: number;
  currency?: string;
  taxAndFeesAmount?: number;
  taxAmount?: number;
  feesAmount?: number;
};

const getFeeIdsForBIEvent = ({ calculatedFees = [] }: { calculatedFees?: CalculatedFee[] }) => {
  const feeIds = calculatedFees.map((fee: CalculatedFee) => fee.ruleId).join(',');
  return feeIds;
};

export const getServiceFeesBIEventParamsSelector = createSelector(
  [
    (state: AppState) => state.session.restaurant.currency,
    (state: AppState) => state.session.restaurantLocations,
    (state: AppState) => state.session.restaurant.currentLocationId,
    (state: AppState) => cartSummarySelector(state),
    (state: AppState) => state.checkout.calculatedFees,
  ],
  (currency, restaurantLocations, locationId, cartSummary, calculatedFees = []) => {
    const { priceComponents } = cartSummary;
    const feeIds = getFeeIdsForBIEvent({ calculatedFees });
    const taxAndFeesAmount = priceComponents.taxesAndServiceFeesTotalAmount;
    const taxAmount = priceComponents.tax;
    const restaurantsLocationId = getRestLocationId({ locationId, restaurantLocations });

    return {
      feeIds,
      feesCount: calculatedFees.length,
      currency,
      taxAndFeesAmount,
      taxAmount,
      feesAmount: taxAndFeesAmount - taxAmount,
      restaurantsLocationId,
    };
  },
);
