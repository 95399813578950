import { StyleParamType, wixColorParam, wixFontParam, createStylesParams, createStylesParam } from '@wix/tpa-settings';
import { getIdFromColorType, getIdFromIconType } from '../../core/logic/labelsLogic';
import { stylesParamsMobileDesign, IStylesParamsMobileDesign } from './stylesParamsMobileDesign';

export type IStylesParams = {
  color4: StyleParamType.Color;
  color5: StyleParamType.Color;
  titleColor: StyleParamType.Color;
  textColor: StyleParamType.Color;
  titleFont: StyleParamType.Font;
  textFont: StyleParamType.Font;
  'wixorders.menubackground': StyleParamType.Color;
  'wixorders-action-color': StyleParamType.Color;
  'wixorders-special-dish-label-color': StyleParamType.Color;
  'wixorders-borders-color': StyleParamType.Color;
  labelsColorType: StyleParamType.Number;
  labelsIconType: StyleParamType.Number;
  labelsPrimaryColor: StyleParamType.Color;
  labelsSecondaryColor: StyleParamType.Color;
  customLabelsPrimaryColor: StyleParamType.Color;
  customLabelsSecondaryColor: StyleParamType.Color;
  shouldShowCustomLabels: StyleParamType.Boolean;
  customLabelsIconType: StyleParamType.Number;
  sectionImageHeight: StyleParamType.Number;
  responsive: StyleParamType.Boolean;
  cardCornerRadius: StyleParamType.Number;
  cardSpacing: StyleParamType.Number;
  sideBySideSpacing: StyleParamType.Number;
} & IStylesParamsMobileDesign;

const legacyColor5 = createStylesParam('color-5', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});

const color5 = createStylesParam('color.5', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});

const color4 = createStylesParam('color.4', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-4'),
});

const legacyTitleColor = createStylesParam('wixorders.titles', {
  type: StyleParamType.Color,
  getDefaultValue: (props) => {
    const value = props.getStyleParamValue ? props.getStyleParamValue(legacyColor5) : undefined;
    return value ? value : wixColorParam('color-5')(props);
  },
});

const legacyTextColor = createStylesParam('wixorders.texts', {
  type: StyleParamType.Color,
  getDefaultValue: (props) => {
    const value = props.getStyleParamValue ? props.getStyleParamValue(legacyColor5) : undefined;
    return value ? value : wixColorParam('color-5')(props);
  },
});

const legacyTitleFont = createStylesParam('wixorders.titles', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Page-title', { size: 20 }),
});

const legacyTextFont = createStylesParam('wixorders.texts', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-M', { size: 16 }),
});

const titleFont = createStylesParam('title-font', {
  type: StyleParamType.Font,
  getDefaultValue: (props) => {
    const value = props.getStyleParamValue ? props.getStyleParamValue(legacyTitleFont) : undefined;
    return value ? value : wixFontParam('Page-title', { size: 20 })(props);
  },
});

const textFont = createStylesParam('text-font', {
  type: StyleParamType.Font,
  getDefaultValue: (props) => {
    const value = props.getStyleParamValue ? props.getStyleParamValue(legacyTextFont) : undefined;
    return value ? value : wixFontParam('Body-M', { size: 16 })(props);
  },
});

const titleColor = createStylesParam('title-color', {
  type: StyleParamType.Color,
  getDefaultValue: (props) => {
    const value = props.getStyleParamValue
      ? props.getStyleParamValue(legacyTitleColor) || props.getStyleParamValue(legacyColor5)
      : undefined;
    return value ? value : wixColorParam('color-5')(props);
  },
});

const textColor = createStylesParam('text-color', {
  type: StyleParamType.Color,
  getDefaultValue: (props) => {
    const value = props.getStyleParamValue
      ? props.getStyleParamValue(legacyTextColor) || props.getStyleParamValue(legacyColor5)
      : undefined;
    return value ? value : wixColorParam('color-5')(props);
  },
});

const bordersColor = createStylesParam('wixorders-borders-color', {
  type: StyleParamType.Color,
  getDefaultValue: (props) => {
    const value = props.getStyleParamValue
      ? props.getStyleParamValue(legacyTextColor) || props.getStyleParamValue(legacyColor5)
      : undefined;
    return value ? value : wixColorParam('color-5')(props);
  },
});

export default createStylesParams<IStylesParams>({
  color4,
  color5,
  titleColor,
  titleFont,
  textColor,
  textFont,

  // background
  'wixorders-action-color': {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  'wixorders.menubackground': {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  'wixorders-special-dish-label-color': {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  // borders
  'wixorders-borders-color': bordersColor,

  // dish labels
  labelsColorType: {
    key: 'wixorders.colortype',
    type: StyleParamType.Number,
    getDefaultValue: () => getIdFromColorType('classic'),
  },
  labelsIconType: {
    key: 'wixorders.icontype',
    type: StyleParamType.Number,
    getDefaultValue: () => getIdFromIconType('outline'),
  },
  labelsPrimaryColor: {
    key: 'wixorder.iconprimarycolor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  labelsSecondaryColor: {
    key: 'wixorder.iconsecondarycolor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  customLabelsPrimaryColor: {
    key: 'wixorder.iconcustomprimarycolor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-11'),
  },
  customLabelsSecondaryColor: {
    key: 'wixorder.iconcustomsecondarycolor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-23'),
  },
  shouldShowCustomLabels: {
    key: 'wixorders-icon-labels-is-custom',
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  customLabelsIconType: {
    key: 'wixorders.customicontype',
    type: StyleParamType.Number,
    getDefaultValue: () => getIdFromIconType('full-bg'),
  },
  sectionImageHeight: {
    key: 'section-image-height',
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 160 : 240),
  },
  responsive: {
    key: 'responsive',
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  cardCornerRadius: {
    key: 'card-corner-radius',
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  cardSpacing: {
    key: 'card-spacing',
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  sideBySideSpacing: {
    key: 'side-by-side-spacing',
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  ...stylesParamsMobileDesign,
});
