import React from 'react';

export interface RouteWrapperProps {
  onEnter?: () => void;
  onExit?: () => void;
  children?: React.ReactNode;
}

const RouteWrapper: React.FC<RouteWrapperProps> = ({ onEnter, onExit, children }) => {
  React.useEffect(() => {
    if (onEnter) {
      onEnter();
    }

    if (onExit) {
      return () => {
        onExit();
      };
    }
  }, [onEnter, onExit]);

  return children ? <>{children}</> : <div />;
};

export default RouteWrapper;
