import styles from './MediaQueryWrapper.scss';
import React from 'react';
import classNames from 'classnames';

interface Props {
  desktopOnly?: boolean;
  mobileOnly?: boolean;
  className?: string;
  children: React.ReactNode;
}

const MediaQueryWrapper: React.FC<Props> = ({ desktopOnly = false, mobileOnly = false, className, children }) => {
  return (
    <React.Fragment>
      <div
        className={classNames([desktopOnly && styles.desktopWrapper, mobileOnly && styles.mobileWrapper, className])}
      >
        {children}
      </div>
    </React.Fragment>
  );
};

export default MediaQueryWrapper;
