import ContactInformation from './ContactInformation';
import { AppState } from '../../../../state/createStore';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { setContact } from '../../../../state/checkout/checkout.actions';
import { saveStateToSessionStorage } from '../../../../state/session/session.actions';

function mapStateToProps(state: AppState, ownProps: any) {
  return {
    ...ownProps,
    countryCode: state.session.restaurant.address.countryCode,
    isUserLoggedIn: state.session.isUserLoggedIn,
    hasMembersAreaIntegration: state.platformParams.settings.hasMembersAreaIntegration,
    isMAInstalled: state.session.isMAInstalled,
    siteIsTemplate: state.platformParams?.instance?.siteIsTemplate,
    isMobile: state.platformParams.isMobile,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ setContact, saveStateToSessionStorage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactInformation);
