import { DisplayableOrderItem } from '@wix/restaurants-client-logic';

export function isOrderItemListValid(displayableOrderItems: DisplayableOrderItem[]) {
  const index = displayableOrderItems.findIndex((doi) => {
    if (doi.errors.length > 0 || doi.isDishDeleted) {
      return true;
    } else {
      return Boolean(
        doi.variations.find((v) => v.choices.some((c) => c.isSelected && (c.errors.length > 0 || c.isDishDeleted))),
      );
    }
  });

  return {
    isValid: index === -1,
    firstInvalidItem: index,
  };
}
