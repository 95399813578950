import CartModal from './CartModal';
import { AppState } from '../../../../state/createStore';
import { connect } from 'react-redux';
import { cartSummarySelector } from '../../../../state/selectors/cartSummarySelector';
import { allBusinessNotificationsSelectorByDispatchTime } from '../../../../state/selectors/businessNotificationSelector';
import { bindActionCreators, Dispatch } from 'redux';
import { openModal } from '../../../../state/session/session.actions';
import { clickGoToCheckout } from '../../../../state/checkout/checkout.actions';
import { getServiceFeesBIEventParamsSelector } from '../../../../state/selectors/serviceFeeBIEventSelector';

function mapStateToProps(state: AppState, ownProps: any) {
  const { displayableOrderItems, totalOrderPrice } = cartSummarySelector(state);
  const address = state.checkout.dispatch.type === 'delivery' ? state.checkout.dispatch.address : undefined;

  return {
    itemCount: state.cart.orderItems.length,
    displayableOrderItems,
    siteIsTemplate: state.platformParams?.instance?.siteIsTemplate,
    businessNotifications: allBusinessNotificationsSelectorByDispatchTime(state).map((bnd) => bnd.notification),
    isMobile: state.platformParams.isMobile,
    isCalculatingServiceFees: state.checkout.isCalculatingServiceFees,
    address,
    dispatch: state.checkout.dispatch,
    totalOrderPrice,
    serviceFeeBIEventParams: getServiceFeesBIEventParamsSelector(state),
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      openModal,
      clickGoToCheckout,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CartModal);
