import CheckoutExpress from './CheckoutExpress';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import { cartSummarySelector } from '../../../../state/selectors/cartSummarySelector';

function mapStateToProps(state: AppState) {
  const { displayableOrderItems, priceComponents } = cartSummarySelector(state);

  return {
    instance: state.platformParams.instance,
    signedInstance: state.platformParams.signedInstance || '',
    language: state.session.siteLanguage || state.session.restaurant.locale.split('_')[0],
    currency: state.session.restaurant.currency,
    paymentMethod: state.checkout.pendingCashierPayment.paymentMethod,
    contact: state.checkout.contact,
    isContactlessDeliveryChecked: state.checkout.isContactlessDeliveryChecked,
    savedAddresses: state.session.savedAddresses,
    priceComponents,
    displayableOrderItems,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutExpress);
