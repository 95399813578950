import NavigationHeader, { NavigationHeaderEntity } from './NavigationHeader';
import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import { bindActionCreators, Dispatch } from 'redux';
import { getDisplayableMenuSelector } from '../../../../state/selectors/menuViewSelector';

function mapStateToProps(state: AppState) {
  const displayableMenu = getDisplayableMenuSelector(state);
  const menus: NavigationHeaderEntity[] = displayableMenu.map((sm) => ({
    id: sm.id,
    slug: sm.slug,
    title: sm.displayableTitle,
  }));
  const sections: Record<string, NavigationHeaderEntity[]> = {};
  displayableMenu.forEach((sm) => {
    sections[sm.id] = sm.sections.map((v) => ({ id: v.id, slug: v.slug, title: v.displayableTitle }));
  });

  return {
    displayableMenu,
    menus,
    sections,
    isRTL: state.platformParams.isRTL,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationHeader);
