import { createSelector } from 'reselect';
import { Experiments } from '@wix/yoshi-flow-editor';
import { AppState } from '../createStore';

export const selectIsCatalogsV3 = createSelector(
  (state: AppState) => state.session.experiments,
  (experimentsObject) => {
    if (!experimentsObject) {
      return false;
    }
    const experiments = new Experiments({ experiments: experimentsObject });
    return experiments.enabled('specs.restaurants.catalogs-v3-migration');
  },
);
