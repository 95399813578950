import React from 'react';

export interface CheckmarkSvgProps {
  className?: string;
  'data-hook'?: string;
}

const CheckmarkSvg: React.FC<CheckmarkSvgProps> = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      data-hook={props['data-hook']}
    >
      <g fill="none">
        <g fill="currentColor">
          <g>
            <g>
              <path
                d="M18.28 6.287L19.701 7.666 10.308 17.679 4.304 12.876 5.516 11.308 10.118 14.989z"
                transform="translate(-250 -212) translate(250 208) translate(0 4)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

CheckmarkSvg.displayName = 'CheckmarkSvg';

export default CheckmarkSvg;
