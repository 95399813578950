import React from 'react';
import {
  TextButton as TpaTextButton,
  TextButtonProps as TpaTextButtonProps,
  TextButtonPriority as TEXT_BUTTON_PRIORITY,
} from 'wix-ui-tpa';
import { st, classes } from './TextButton.st.css';

export interface TextButtonProps extends TpaTextButtonProps {
  colorless?: boolean;
}

const TextButton: React.FC<TextButtonProps> = ({ colorless, ...props }) => {
  return (
    <TpaTextButton {...props} className={st(colorless ? classes.rootColorless : classes.root, {}, props.className)} />
  );
};

TextButton.displayName = 'TextButton';

export { TextButton };
export { TEXT_BUTTON_PRIORITY };
