import { createAction } from '../common/actionUtils';
import {
  SetPendingOrderItemPayload,
  CreateNewPendingOrderItemPayload,
  OpenExistingOrderItemPayload,
  SetOrderCommentPayload,
  AddOrderCouponPayload,
  AddOrderCouponSuccessPayload,
  AddOrderCouponFailurePayload,
  SetOrderItemsPayload,
  RemoveOrderItemPayload,
  ChangeOrderItemCountPayload,
  ClickOnMenuItemPayload,
  ClickAddItemToCartPayload,
} from './cart.actions.types';

export const setPendingOrderItem = createAction<SetPendingOrderItemPayload>('SET_PENDING_ORDER_ITEM');

export const createNewPendingOrderItem = createAction<CreateNewPendingOrderItemPayload>(
  'CREATE_NEW_PENDING_ORDER_ITEM',
);

export const addPendingOrderItemToCart = createAction('ADD_PENDING_ORDER_ITEM_TO_CART');

export const openExistingOrderItem = createAction<OpenExistingOrderItemPayload>('OPEN_EXISTING_ORDER_ITEM');

export const setOrderComment = createAction<SetOrderCommentPayload>('SET_ORDER_COMMENT');

export const addOrderCoupon = createAction<AddOrderCouponPayload>('ADD_ORDER_COUPON');

export const addOrderCouponSuccess = createAction<AddOrderCouponSuccessPayload>('ADD_ORDER_COUPON_SUCCESS');

export const addOrderCouponFailure = createAction<AddOrderCouponFailurePayload>('ADD_ORDER_COUPON_FAILURE');

export const removeOrderCoupon = createAction('REMOVE_ORDER_COUPON');

export const setOrderItems = createAction<SetOrderItemsPayload>('SET_ORDER_ITEMS');

export const removeOrderItem = createAction<RemoveOrderItemPayload>('REMOVE_ORDER_ITEM');

export const changeOrderItemCount = createAction<ChangeOrderItemCountPayload>('CHANGE_ORDER_ITEM_COUNT');

export const clickOnMenuItem = createAction<ClickOnMenuItemPayload>('CLICK_ON_MENU_ITEM');

export const clickAddItemToCart = createAction<ClickAddItemToCartPayload>('CLICK_ON_ADD_ITEM_TO_CART');

export const clearCart = createAction('CLEAR_CART');
