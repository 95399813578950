import React, { useMemo, useEffect } from 'react';
import classNames from 'classnames';

import { useExperiments, useTranslation, useBi } from '@wix/yoshi-flow-editor';
import { DispatchType, Policy } from '@wix/restaurants-client-logic';
import { SectionNotification, ButtonPriority as PRIORITY } from 'wix-ui-tpa';
import { checkoutInformativeMessage } from '@wix/bi-logger-olo-client/v2';

import { Modals } from '../../../../core/constants';
import { OpenModalPayload } from '../../../../state/session/session.actions.types';
import { SetContactlessDeliveryPayload } from '../../../../state/checkout/checkout.actions.types';
import dataHooks from '../../data-hooks';

import Text from '../../core-components/Text';
import PolicyView from '../PolicyView';
import Button from '../Button';
import WarningSignIcon from '../../../../assets/icons/WarningSign.svg';
import styles from './CheckoutSubmit.scss';
import ContactlessDeliveryToggle from '../ContactlessDeliveryToggle';

interface CheckoutSubmitProps {
  orderPolicy?: Policy;
  paymentMethod?: string;
  isContactlessDeliveryChecked?: boolean;
  openModal: (payload: OpenModalPayload) => void;
  submitOrder: Function;
  isLoading?: boolean;
  dispatchType: DispatchType;
  setContactlessDelivery: (payload: SetContactlessDeliveryPayload) => void;
  siteIsTemplate?: boolean;
  orderPacingLevel?: number;
  isFutureOrder?: boolean;
  getCurrentPacingLevel: Function;
}

export const CheckoutSubmit: React.FC<CheckoutSubmitProps> = ({
  orderPolicy,
  dispatchType,
  paymentMethod,
  openModal,
  submitOrder,
  isLoading,
  siteIsTemplate,
  orderPacingLevel,
  isFutureOrder,
  getCurrentPacingLevel,
}) => {
  const initialOrderPacingLevel = useMemo(() => orderPacingLevel || 0, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    getCurrentPacingLevel();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const { t } = useTranslation();
  const biLogger = useBi();
  const [showCheckboxError, setShowCheckboxError] = React.useState(false);
  const hasCheckbox = Boolean(orderPolicy?.policyCheckbox);
  const checkedByDefault = Boolean(orderPolicy?.policyCheckbox?.checkedByDefault === 'CHECKED_BY_DEFAULT');
  const [isButtonDisabled, setButtonDisabled] = React.useState(hasCheckbox && !checkedByDefault);
  const handlePolicyChange = React.useCallback(() => {
    setButtonDisabled(!isButtonDisabled);
    setShowCheckboxError(false);
  }, [isButtonDisabled]);
  const { experiments } = useExperiments();
  const expressCheckoutEnabled = experiments.enabled('specs.restaurants.express-checkout');
  const shouldShowContactlessOption =
    !expressCheckoutEnabled && dispatchType === 'delivery' && paymentMethod !== 'offline';
  const templatesDemoExperimentEnabled = experiments.enabled('specs.restaurants.templatesDemo');
  const shouldOpenTemplatesModal = siteIsTemplate && templatesDemoExperimentEnabled;

  const shouldShowOrderPacingWarning = !isFutureOrder && orderPacingLevel && orderPacingLevel > initialOrderPacingLevel;

  useEffect(() => {
    if (shouldShowOrderPacingWarning) {
      biLogger.report(
        checkoutInformativeMessage({
          checkoutStep: 'review-and-place-order',
          messageType: 'order-pacing',
          messageValue: 'delay-alert--kitchen-is-busy',
        }),
      );
    }
  }, [shouldShowOrderPacingWarning, biLogger]);

  return (
    <div className={styles.wrapper}>
      {orderPolicy && (
        <PolicyView
          className={styles.policies}
          hasCheckbox={hasCheckbox}
          checkedByDefault={!isButtonDisabled}
          termsAndConditions={orderPolicy.termsAndConditions}
          privacyPolicy={orderPolicy.privacyPolicy}
          onChange={handlePolicyChange}
          openModal={openModal}
          showError={showCheckboxError}
        />
      )}
      {shouldShowContactlessOption && <ContactlessDeliveryToggle />}
      {shouldShowOrderPacingWarning ? (
        <SectionNotification
          className={classNames(styles.orderPacingNotification, {
            [styles.pullUp]: !shouldShowContactlessOption,
          })}
          data-hook={dataHooks.checkoutReviewOrdePacingText}
          type="alert"
          size="compact"
        >
          <SectionNotification.Icon icon={<WarningSignIcon />} />
          <SectionNotification.Text>
            {t('checkout_main_order_pacing_ASAP_notification_delivery_pace1')}
          </SectionNotification.Text>
        </SectionNotification>
      ) : null}
      <Button
        upgrade
        onClick={() => {
          if (isButtonDisabled) {
            setShowCheckboxError(true);
          } else {
            shouldOpenTemplatesModal ? openModal({ modal: Modals.TEMPLATES_MODAL }) : submitOrder();
          }
        }}
        data-hook={dataHooks.checkoutReviewSubmitOrder}
        fullWidth
        priority={PRIORITY.primary}
        className={styles.continueButton}
        loading={isLoading}
        disabled={isLoading}
      >
        <Text typography="p2-m-colorless">{t('checkout_main_orderreview_submit_button')}</Text>
      </Button>
    </div>
  );
};
CheckoutSubmit.displayName = 'CheckoutSubmit';

export default CheckoutSubmit;
