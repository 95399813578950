import { createSelector } from 'reselect';
import { AppState } from '../createStore';
import { isCoupon } from '@wix/restaurants-client-logic';

export const hasCouponsSelector = createSelector(
  (state: AppState) => state.session.charges,
  (charges) => {
    return charges?.some(isCoupon);
  },
);
