import LabelsView from './LabelsView';
import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';

function mapStateToProps(state: AppState) {
  return {
    labelsSettings: state.platformParams.labelsSettings,
  };
}

export default connect(mapStateToProps)(LabelsView);
