import CheckoutSubmit from './CheckoutSubmit';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import { setContactlessDelivery, submitOrder } from '../../../../state/checkout/checkout.actions';
import { getOrderPolicy } from '../../../../core/logic/policyLogic';
import { openModal, getCurrentPacingLevel } from '../../../../state/session/session.actions';
import { selectOrderPacingLevel } from '../../../../state/selectors/orderPacingSelector';
import { selectIsFutureOrder } from '../../../../state/selectors/checkoutSelector';

function mapStateToProps(state: AppState) {
  return {
    orderPolicy: getOrderPolicy(state.session.restaurant),
    paymentMethod: state.checkout.pendingCashierPayment.paymentMethod,
    dispatchType: state.checkout.dispatch.type,
    isContactlessDeliveryChecked: state.checkout.isContactlessDeliveryChecked,
    isLoading: state.checkout.isLoading,
    siteIsTemplate: state.platformParams?.instance?.siteIsTemplate,
    orderPacingLevel: selectOrderPacingLevel(state),
    isFutureOrder: selectIsFutureOrder(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ submitOrder, openModal, setContactlessDelivery, getCurrentPacingLevel }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSubmit);
