import React from 'react';
import { Counter, CounterProps } from 'wix-ui-tpa';
import { classes } from './Quantity.st.css';

type QuantityProps = CounterProps & { halfWidth?: boolean; small?: boolean };

const Quantity: React.FC<QuantityProps> = (props) => {
  return <Counter {...props} className={getClassName(props)} />;
};

Quantity.displayName = 'Quantity';

function getClassName(props: QuantityProps) {
  if (props.halfWidth) {
    return classes.rootHalfWidth;
  } else if (props.small) {
    return classes.rootSmall;
  } else {
    return classes.root;
  }
}

export default Quantity;
