import SmsModal from './SmsModal';
import { AppState } from '../../../../state/createStore';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { abortSmsVerification, submitSmsCode } from '../../../../state/checkout/checkout.actions';

function mapStateToProps(state: AppState) {
  return {
    phoneNumber: state.checkout.contact.phone,
    hasSmsError: state.checkout.hasSmsError,
    loading: state.checkout.isSmsLoading,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  const bound = bindActionCreators(
    {
      submitSmsCode,
      abortSmsVerification,
    },
    dispatch,
  );

  return {
    ...bound,
    onSubmit: (code: string) => bound.submitSmsCode({ code }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SmsModal);
