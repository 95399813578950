import React from 'react';
import { FloatingDropdown as TpaFloatingDropdown, FloatingDropdownProps as TpaFloatingDropdownProps } from 'wix-ui-tpa';
import { st, classes } from './FloatingDropdown.st.css';

export interface FloatingDropdownProps extends TpaFloatingDropdownProps {}

const FloatingDropdown: React.FC<FloatingDropdownProps> = (props) => {
  return <TpaFloatingDropdown {...props} className={st(classes.root, {}, props.className)} />;
};

FloatingDropdown.displayName = 'FloatingDropdown';

export default FloatingDropdown;
