import React from 'react';
import styles from './CartTaxAndFees.scss';
import Text from '../../core-components/Text';
import dataHooks from '../../data-hooks';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ServiceFee } from '@wix/restaurants-client-logic';
import TaxAndFeesPopover from './TaxAndFeesPopover';

export interface CartTaxAndFeesProps {
  tax?: string;
  serviceFees?: ServiceFee[];
  taxesAndServiceFeesTotalAmount?: string;
}

const CartTaxAndFees: React.FC<CartTaxAndFeesProps> = ({ tax, serviceFees, taxesAndServiceFeesTotalAmount }) => {
  const { t } = useTranslation();
  const hasOnlyTax = tax && (!serviceFees || serviceFees.length === 0);
  const hasOnlyOneServiceFeeAndNoTax = !tax && serviceFees && serviceFees.length === 1;
  const shouldNotRenderPopover = hasOnlyTax || hasOnlyOneServiceFeeAndNoTax;
  return (
    <React.Fragment>
      {shouldNotRenderPopover ? (
        <div className={styles.row}>
          <Text typography="p2-m" className={styles.title} data-hook={dataHooks.cartSubtotalTaxTitle}>
            {tax ? t('cart_summary_tax', 'Tax') : serviceFees![0].name || t('cart_summary_service_fee', 'Service Fee')}
          </Text>
          <Text typography="p2-m" className={styles.price} data-hook={dataHooks.cartSubtotalTaxTotalAmount}>
            {taxesAndServiceFeesTotalAmount}
          </Text>
        </div>
      ) : (
        <TaxAndFeesPopover
          tax={tax}
          serviceFees={serviceFees}
          taxesAndServiceFeesTotalAmount={taxesAndServiceFeesTotalAmount}
        />
      )}
    </React.Fragment>
  );
};

CartTaxAndFees.displayName = 'CartTaxAndFees';

export default CartTaxAndFees;
