import React from 'react';
import { RadioButton as TpaRadioButton, RadioButtonProps as TpaRadioButtonProps } from 'wix-ui-tpa';
import { st, classes } from './RadioButton.st.css';

export interface RadioButtonProps extends TpaRadioButtonProps {}

const RadioButton: React.FC<RadioButtonProps> = (props) => {
  return <TpaRadioButton {...props} className={st(classes.root, {}, '')} />;
};

RadioButton.displayName = 'RadioButton';

export default RadioButton;
