import { createSelector } from 'reselect';
import { AppState } from '../createStore';
import moment from 'moment-timezone';
import { getDisplayableMenu, getDisplayableCatalog, CatalogPlatform } from '@wix/restaurants-client-logic';
import { selectIsCatalogsV3 } from './experimentsSelector';

export const getTakeoutDisplayableMenuSelector = createSelector(
  (state: AppState) => state.session.menu,
  (state: AppState) => state.session.catalog,
  (state: AppState) => state.session.restaurant.locale,
  (state: AppState) => state.session.restaurant.currency,
  (state: AppState) => state.session.restaurant.timezone,
  (state: AppState) => (state.platformParams.isMobile ? CatalogPlatform.MOBILE_SITE : CatalogPlatform.SITE),
  (state: AppState) => state.checkout.dispatch.time,
  (state: AppState) => selectIsCatalogsV3(state),
  (menu, catalog, locale, currency, timezone, platform, time, isCatalogsV3) => {
    const dispatchTime = moment(time).tz(timezone);
    if (isCatalogsV3) {
      return getDisplayableCatalog(catalog, locale, currency, dispatchTime, platform, 'takeout');
    } else {
      return getDisplayableMenu(
        menu,
        locale,
        currency,
        dispatchTime,
        platform === CatalogPlatform.MOBILE_SITE ? 'mobileweb' : 'web',
        'takeout',
      );
    }
  },
);

export const getDeliveryDisplayableMenuSelector = createSelector(
  (state: AppState) => state.session.menu,
  (state: AppState) => state.session.catalog,
  (state: AppState) => state.session.restaurant.locale,
  (state: AppState) => state.session.restaurant.currency,
  (state: AppState) => state.session.restaurant.timezone,
  (state: AppState) => (state.platformParams.isMobile ? CatalogPlatform.MOBILE_SITE : CatalogPlatform.SITE),
  (state: AppState) => state.checkout.dispatch.time,
  (state: AppState) => selectIsCatalogsV3(state),
  (menu, catalog, locale, currency, timezone, platform, time, isCatalogsV3) => {
    const dispatchTime = moment(time).tz(timezone);
    if (isCatalogsV3) {
      return getDisplayableCatalog(catalog, locale, currency, dispatchTime, platform, 'delivery');
    } else {
      return getDisplayableMenu(
        menu,
        locale,
        currency,
        dispatchTime,
        platform === CatalogPlatform.MOBILE_SITE ? 'mobileweb' : 'web',
        'delivery',
      );
    }
  },
);

export const getDisplayableMenuSelector = createSelector(
  (state: AppState) => state.checkout.dispatch.type,
  (state: AppState) => getTakeoutDisplayableMenuSelector(state),
  (state: AppState) => getDeliveryDisplayableMenuSelector(state),
  (type, takeoutDisplayableMenu, deliveryDisplayableMenu) => {
    if (type === 'takeout') {
      return takeoutDisplayableMenu;
    } else {
      return deliveryDisplayableMenu;
    }
  },
);
