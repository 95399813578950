import React from 'react';
import dataHooks from '../../data-hooks';
import { limitationToString } from './variationLimitationUtils';
import { VariationLimitation } from '@wix/restaurants-client-logic';
import { useTranslation } from '@wix/yoshi-flow-editor';

export interface VariationLimitationViewProps {
  limitation: VariationLimitation;
  min?: number;
  max?: number;
  className?: string;
}

const VariationLimitationView: React.FC<VariationLimitationViewProps> = ({ limitation, min, max, className }) => {
  const { t } = useTranslation();
  const str = limitationToString(t, limitation, min, max);
  return str ? (
    <div className={className} data-hook={dataHooks.dishModalVariationLimitation}>
      {str}
    </div>
  ) : null;
};

VariationLimitationView.displayName = 'VariationLimitationView';

export default VariationLimitationView;
