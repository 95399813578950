import NoBusinessAddressNotification from './NoBusinessAddressNotification';
import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';

function mapStateToProps(state: AppState) {
  return {
    restaurantAddress: state.session.restaurant.address,
    metaSiteId: state.platformParams.instance.metaSiteId,
  };
}

export default connect(mapStateToProps)(NoBusinessAddressNotification);
