import TemplatesModal from './TemplatesModal';
import { AppState } from '../../../../state/createStore';
import { connect } from 'react-redux';

function mapStateToProps(state: AppState) {
  return {
    siteIsTemplate: state.platformParams?.instance?.siteIsTemplate,
  };
}

export default connect(mapStateToProps)(TemplatesModal);
