import Main from './Main';
import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';

function mapStateToProps(state: AppState) {
  return {
    isRTL: state.platformParams.isRTL,
    isMobile: state.platformParams.isMobile,
    compId: state.platformParams.compId,
  };
}

export default connect(mapStateToProps)(Main);
