/**
* Wait until condition return true or timeout
* @param {() => boolean} condition return true to resolve
* @param {number} timeout seconds, default value = 10 seconds
* @param {number} checkInterval milliseconds, default value = 100 milliseconds
*/
export const waitUntil = async (condition: () => boolean, timeout = 10, checkInterval = 100) => {
  const startTime = Date.now();
  const timeoutInMiliseconds = timeout * 1000;
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      const timeDiff = Date.now() - startTime;
      if (!condition() && timeDiff <= timeoutInMiliseconds) {
        return;
      }
      clearInterval(interval);
      resolve(true);
    }, checkInterval);
  });
};