import DineInClosedModal from './DineInClosedModal';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { removeDispatchTypeFromURL, setIsLocationPicked } from '../../../../state/session/session.actions';

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ setIsLocationPicked, removeDispatchTypeFromURL }, dispatch);
}

export default connect(undefined, mapDispatchToProps)(DineInClosedModal);
