import React, { ReactNode } from 'react';
import { Popover as TpaPopover, PopoverProps as TpaPopoverProps } from 'wix-ui-tpa';
import { st, classes } from './Popover.st.css';

export interface PopoverProps extends TpaPopoverProps {
  popoverElement: ReactNode;
  popoverContent: ReactNode;
}

const Popover: React.FC<PopoverProps> = ({ popoverElement, popoverContent, ...props }) => {
  return (
    <TpaPopover {...props} className={st(classes.root, {}, props.className)}>
      <TpaPopover.Element>{popoverElement}</TpaPopover.Element>
      <TpaPopover.Content>{popoverContent}</TpaPopover.Content>
    </TpaPopover>
  );
};

Popover.displayName = 'Popover';

export default Popover;
