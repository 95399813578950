import React, { useEffect, useState } from 'react';
import { WixAtlasServiceWeb } from '@wix/ambassador-wix-atlas-service-web/http';
import dataHooks from '../../data-hooks';
import styles from './EmbeddedMap.scss';
import { Address } from '@wix/restaurants-client-logic';

export interface EmbeddedMapProps {
  address: Address;
  className?: string;
}

async function getUrl(address: Address) {
  const { getEmbedMap } = WixAtlasServiceWeb('/api/wix-atlas-service-web').MapsWidgetServiceV0()();
  const { url } = await getEmbedMap({ query: encodeURIComponent(address.formatted) });
  return url;
}

const EmbeddedMap: React.FC<EmbeddedMapProps> = ({ address, className }) => {
  const [src, setSrc] = useState('');

  useEffect(() => {
    getUrl(address).then((url) => setSrc(url || ''));
  }, [address]);

  return (
    <div className={`${styles.wrapper} ${className}`} data-hook={dataHooks.map}>
      {src && <iframe src={src} title={address.formatted} width="100%" height="100%" />}
    </div>
  );
};

EmbeddedMap.displayName = 'EmbeddedMap';

export default EmbeddedMap;
