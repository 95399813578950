import ConnectPaymentModal from './ConnectPaymentModal';
import { AppState } from '../../../../state/createStore';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

function mapStateToProps(state: AppState, ...ownProps: any) {
  return {
    metaSiteId: state.platformParams.instance.metaSiteId,
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(ConnectPaymentModal);
