import _ from 'lodash';
import { Policy, Restaurant } from '@wix/restaurants-client-logic';

export function isOrderPolicy(policy: any): policy is Policy {
  return Boolean(policy && (policy.policyCheckbox || policy.termsAndConditions || policy.privacyPolicy));
}

export function getOrderPolicy(restaurant: Restaurant): Policy | undefined {
  const rawPolicy = _.get(restaurant, 'orders.properties.policy', '{}');
  const parsedPolicy = JSON.parse(rawPolicy);
  return isOrderPolicy(parsedPolicy) ? (parsedPolicy as Policy) : undefined;
}
