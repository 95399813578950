import React from 'react';
import styles from './ErrorBanner.scss';
import { SectionNotification } from 'wix-ui-tpa';
import Error from 'wix-ui-icons-common/on-stage/Error';

export interface ErrorBannerProps {
  key?: string;
  value: string;
  'data-hook'?: string;
}

const ErrorBanner: React.FC<ErrorBannerProps> = (props) => {
  return (
    <SectionNotification data-hook={props['data-hook']} type="error" className={styles.wrapper}>
      <SectionNotification.Icon icon={<Error />} />
      <SectionNotification.Text>{props.value}</SectionNotification.Text>
    </SectionNotification>
  );
};

ErrorBanner.displayName = 'ErrorBanner';

export default ErrorBanner;
