import React, { useEffect } from 'react';
import Text from '../../core-components/Text';
import styles from './AddressInformation.scss';
import TextField from '../TextField';
import { SetDeliveryAddressFieldPayload } from '../../../../state/addressInformationForm/addressForm.actions.types';
import { Address } from '@wix/restaurants-client-logic';

export interface AddressInformationInputProps {
  address: Address;
  field: keyof Address;
  onChange: (payload: SetDeliveryAddressFieldPayload) => void;
  label: string;
  errorMessage?: string;
  className?: string;
  placeholder?: string;
  'data-hook': string;
}

const AddressInformationInput: React.FC<AddressInformationInputProps> = ({
  address,
  field,
  onChange,
  label,
  errorMessage,
  className,
  placeholder,
  ...rest
}) => {
  const [stateValue, setStateValue] = React.useState((address[field] as string) || ''); // prevent cursor bugs

  useEffect(() => {
    setStateValue((address[field] as string) || '');
  }, [address[field]]);

  return (
    <label className={className}>
      <Text typography="p2-s" className={styles.label}>
        {label}
      </Text>
      <TextField
        maxLength={240}
        value={stateValue}
        onChange={(e) => {
          setStateValue(e.target.value);
          onChange({ addressField: field, value: e.target.value });
        }}
        data-hook={rest['data-hook']}
        error={Boolean(errorMessage)}
        errorMessage={errorMessage}
        placeholder={placeholder}
      />
    </label>
  );
};

AddressInformationInput.displayName = 'AddressInformationInput';

export default AddressInformationInput;
