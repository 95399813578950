import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import CheckoutFlowStepTitle from '../CheckoutFlowStepTitle';
import styles from './CheckoutReview.scss';
import dataHooks from '../../data-hooks';
import Text from '../../core-components/Text';
import Scroller from '../Scroller';

export interface CheckoutReviewProps {
  done?: boolean;
  collapsed?: boolean;
  index?: string;
  describedby?: string;
  isMobile: boolean;
}

const CheckoutReview: React.FC<CheckoutReviewProps> = ({ index = '1', collapsed, done, describedby, isMobile }) => {
  const titleId = `${dataHooks.checkoutReview}-titleId`;
  const { t } = useTranslation();

  return (
    <Scroller name="checkout-review" condition={isMobile && !done && !collapsed}>
      <div
        data-hook={dataHooks.checkoutReview}
        className={styles.wrapper}
        aria-describedby={describedby}
        aria-labelledby={titleId}
      >
        <CheckoutFlowStepTitle
          text={t('checkout_main_orderreview_title')}
          index={index}
          collapsed={collapsed}
          done={done}
          titleId={titleId}
        />
        {!collapsed && (
          <>
            <Text typography="p2-m" className={styles.description}>
              {t('checkout_main_orderreview_text')}
            </Text>
          </>
        )}
      </div>
    </Scroller>
  );
};

CheckoutReview.displayName = 'CheckoutReview';

export default CheckoutReview;
