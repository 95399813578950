import { createAction } from '../common/actionUtils';
import {
  SetAddressInputErrorPayload,
  SetAddressInputValuePayload,
  SetDeliveryAddressFieldPayload,
  SetErrorVisibilityPayload,
  SetSelectedAddressPayload,
  ToggleAllErrorsPayload,
  ValidateFormPayload,
  SetTpaEstimateInlineErrorPayload,
} from './addressForm.actions.types';

export const toggleAllErrors = createAction<ToggleAllErrorsPayload>('SHOW_ALL_ERRORS');
export const setAddressInputValue = createAction<SetAddressInputValuePayload>('SET_ADDRESS_INPUT_VALUE');
export const setSelectedAddress = createAction<SetSelectedAddressPayload>('SET_SELECTED_ADDRESS');
export const setErrorVisibility = createAction<SetErrorVisibilityPayload>('SET_ERROR_VISIBILITY');
export const setAddressInputError = createAction<SetAddressInputErrorPayload>('SET_ADDRESS_INPUT_ERROR');
export const setFieldError = createAction<SetErrorVisibilityPayload>('SET_FIELD_ERROR');
export const setTpaEstimateInlineError = createAction<SetTpaEstimateInlineErrorPayload>(
  'SET_TPA_ESTIMATE_INLINE_ERROR',
);
export const setDeliveryAddressField = createAction<SetDeliveryAddressFieldPayload>('SET_DELIVERY_ADDRESS_FIELD');
export const validateForm = createAction<ValidateFormPayload>('VALIDATE_FORM');
export const initAddressForm = createAction('INIT_ADDRESS_FORM');
export const revertAddress = createAction('REVERT_ADDRESS');
