import React from 'react';
import CartButton from '../CartButton';
import styles from './FloatingCartButton.scss';

export interface FloatingCartButtonProps {}

const FloatingCartButton: React.FC<FloatingCartButtonProps> = () => {
  return (
    <div className={styles.wrapper}>
      <CartButton fullWidth />
    </div>
  );
};

FloatingCartButton.displayName = 'FloatingCartButton';

export default FloatingCartButton;
