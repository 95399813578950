import AddressSelectionModal from './AddressSelectionModal';
import { AppState } from '../../../../state/createStore';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  setDeliveryAddress,
  setDeliveryAddressFromForm,
  setSelectedAddressId,
} from '../../../../state/checkout/checkout.actions';
import { cartSummarySelector } from '../../../../state/selectors/cartSummarySelector';
import { saveAddressToServer } from '../../../../state/session/session.actions';
import {
  initAddressForm,
  setAddressInputError,
  setErrorVisibility,
  setFieldError,
  setSelectedAddress,
  toggleAllErrors,
} from '../../../../state/addressInformationForm/addressForm.actions';
import { selectOpenLocations } from '../../../../state/selectors/locationsSelector';

function mapStateToProps(state: AppState, ...ownProps: any) {
  const { priceComponents } = cartSummarySelector(state);

  return {
    addresses: state.session.savedAddresses,
    defaultAddressId: state.session.defaultAddressId,
    restaurant: state.session.restaurant,
    dispatchTime: state.checkout.dispatch.time,
    totalOrderPrice: priceComponents.total,
    addressInputError: state.addressForm.addressInputError,
    selectedAddressOption: state.addressForm.selectedAddressOption,
    selectedAddressId: state.checkout.selectedAddressId,
    isLoadingAddressesFromServer: state.session.isLoadingAddressesFromServer,
    isMultiLocation: state.session.isMultiLocation,
    locations: selectOpenLocations(state),
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setDeliveryAddress,
      saveAddressToServer,
      setDeliveryAddressFromForm,
      setAddressInputError,
      setErrorVisibility,
      setSelectedAddress,
      setSelectedAddressId,
      initAddressForm,
      setFieldError,
      toggleAllErrors,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressSelectionModal);
