import * as React from 'react';
import ErrorSmall from 'wix-ui-icons-common/on-stage/ErrorSmall';
import { classes, st } from './ErrorMessageWrapper.st.css';
import { ErrorMessageWrapperDefaultProps, ErrorMessageWrapperProps } from './types';
import dataHooks from '../../../data-hooks';

// this component is a "copy paste" from wix-ui-tpa
export class ErrorMessageWrapper extends React.Component<ErrorMessageWrapperProps> {
  static displayName = 'ErrorMessageWrapper';
  static defaultProps: ErrorMessageWrapperDefaultProps = { visible: false };

  _getMessage = () => {
    const { message, messageId, dataHook } = this.props;
    return (
      <div id={messageId} className={classes.message} data-hook={dataHook}>
        <ErrorSmall className={classes.icon} aria-hidden data-hook={dataHooks.addressInformationInputErrorIcon} />
        {message}
      </div>
    );
  };

  render() {
    const { className, children, visible, suffixText } = this.props;
    return (
      <div className={st(classes.root, { visible }, className)}>
        {children}
        {visible &&
          (suffixText ? (
            <div className={classes.messageWithSuffix}>
              {this._getMessage()}
              {suffixText}
            </div>
          ) : (
            this._getMessage()
          ))}
      </div>
    );
  }
}
