import React, { useEffect, useState } from 'react';
import {
  Tip,
  TipType,
  TipPresets,
  TipPresetValues,
  currencySymbol as getCurrencySymbol,
} from '@wix/restaurants-client-logic';
import { SetTipPayload } from '../../../../state/checkout/checkout.actions.types';
import dataHooks from '../../data-hooks';
import CurrencyInput from '../../core-components/CurrencyInput/CurrencyInput';
import Text from '../../core-components/Text/Text';
import { TFunction, useTranslation } from '@wix/yoshi-flow-editor';
import _ from 'lodash';
import styles from './TipPicker.scss';
import { classes } from './TipsBoxSelection.st.css';
import { BoxSelection } from 'wix-ui-tpa';

interface TipPickerProps {
  tip?: Tip;
  currency: string;
  locale: string;
  onTipChange: (payload: SetTipPayload) => void;
  tipPresets: TipPresets;
}

// wix-ui-tpa does not currently support conditional rendering of <BoxSelection.Option> , so need to calculate if custom tip button will be included in the options before rendering
function getBoxSelectionOptions(
  selectedTipPresets: TipPresetValues[],
  selectedId: string | undefined,
  selectedTipType: string,
  t: TFunction,
  currency: string,
  hasCustomTips: boolean,
) {
  const currencySymbol = getCurrencySymbol(currency);
  const boxSelectionOptions = [];

  selectedTipPresets.map((tipPreset, index) => {
    // If tip value is a string we are sure it is the None value
    // This change is is because of a bug in the dashboard that enable users to change the 'None' string. (https://app.asana.com/0/1200266419687129/1201714376215607)
    const isNone = typeof tipPreset.value === 'string';
    const tipValue = isNone ? t('cart_tip_option_none') : tipPreset.value;

    boxSelectionOptions.push(
      <BoxSelection.Option
        id={index.toString()}
        checked={selectedId === index.toString()}
        data-hook={dataHooks.customizeTipPickerPresetButton(index)}
        className={classes.option}
        aria-label={
          t('cart_tip_leavetip_label') +
          ' ' +
          (isNone ? 'none' : selectedTipType === 'percentageValues' ? `${tipValue}%` : `${currencySymbol}${tipValue}`)
        }
      >
        {!isNone ? (
          selectedTipType === 'percentageValues' ? (
            <Text typography="p2-m" data-hook={dataHooks.customizeTipPickerValue(index)}>
              {tipValue}%
            </Text>
          ) : (
            <Text typography="p2-m" data-hook={dataHooks.customizeTipPickerValue(index)}>
              {currencySymbol}
              {Number(tipValue).toFixed(2)}
            </Text>
          )
        ) : (
          <Text typography="p2-m" data-hook={dataHooks.customizeTipPickerValue(index)}>
            {tipValue}
          </Text>
        )}
      </BoxSelection.Option>,
    );
  });

  if (hasCustomTips) {
    boxSelectionOptions.push(
      <BoxSelection.Option
        data-hook={dataHooks.customizeTipPickerCustomAmount}
        id="custom-amount"
        checked={selectedId === 'custom-amount'}
        className={classes.customAmountButton}
        aria-label={`${t('cart_tip_leavetip_label')} ${t('cart_tip_option_custom')}`}
      >
        <Text typography="p2-m">{t('cart_tip_option_custom')}</Text>
      </BoxSelection.Option>,
    );
  }

  return boxSelectionOptions;
}

function getIndexFromTipPresets(tip: Tip | undefined, selectedTipType: string, tipPresets: TipPresetValues[]) {
  const percentagePresetIndex =
    tip && _.findIndex(tipPresets, (t) => t.value === tip.amount || (t.value === 'None' && tip.amount === 0));
  const currencyPresetIndex =
    tip && _.findIndex(tipPresets, (t) => t.value === tip.amount / 100 || (t.value === 'None' && tip.amount === 0));
  return selectedTipType === 'percentageValues' ? percentagePresetIndex : currencyPresetIndex;
}

function getInitialSelectedTipPresetIndex(
  tip: Tip | undefined,
  selectedTipType: string,
  tipPresets: TipPresetValues[],
  defaultTipIndex: string,
  hasCustomTips: boolean,
) {
  const isCustomAmountSelected = tip?.isCustom;
  const initialTipPresetIndexSelected = getIndexFromTipPresets(tip, selectedTipType, tipPresets)?.toString();
  const initialIndexIsDefault = !tip;

  return initialIndexIsDefault
    ? defaultTipIndex
    : isCustomAmountSelected
    ? 'custom-amount'
    : initialTipPresetIndexSelected;
}

const TipPicker: React.FC<TipPickerProps> = ({ tip, onTipChange, currency, locale, tipPresets }: TipPickerProps) => {
  const { t } = useTranslation();
  const tipAmount = tip?.amount || 0;
  const selectedTipType = tipPresets.tipOption;
  const isTipTypePercent = selectedTipType === 'percentageValues';
  const selectedTipPresets = isTipTypePercent ? tipPresets.percentageValues : tipPresets.currencyValues;
  const defaultTip = _.find(selectedTipPresets, (tipVal) => tipVal.isDefault === true);
  const defaultTipValue =
    !defaultTip || typeof defaultTip.value === 'string'
      ? 0
      : isTipTypePercent
      ? defaultTip.value
      : defaultTip.value * 100;
  const defaultTipIndex = _.findIndex(selectedTipPresets, (tipVal) => tipVal.isDefault === true).toString();
  const initialSelectedTipPresetIndex = getInitialSelectedTipPresetIndex(
    tip,
    selectedTipType,
    selectedTipPresets,
    defaultTipIndex,
    tipPresets.customTips,
  );
  const currencySymbol = getCurrencySymbol(currency);
  const [selectedId, setSelectedId] = useState(initialSelectedTipPresetIndex);
  const [isCustomAmount, setIsCustomAmount] = useState(initialSelectedTipPresetIndex === 'custom-amount');
  const [customAmountInputValue, setCustomAmountInputValue] = useState(tipAmount); // will be 0 if selecting the custom amount button or the tip amount if the custom amount button is the initial selected button
  // making sure that the initial tip is calculated into the amount total on first loading of the component
  useEffect(() => {
    onTipChange({
      tip: {
        // initial tip value will be the default tip if tip still undefined and if tip presets changed (meaning we probably switched locations), else it will be the saved amount
        amount: !tip ? defaultTipValue : tipAmount,
        tipType:
          !isTipTypePercent || selectedId === 'custom-amount' ? TipType.TipTypeCurrencyAmount : TipType.TipTypePercent,
        isCustom: selectedId === 'custom-amount',
      },
    });
  }, []);

  return (
    <div className={styles.tipPicker} data-hook={dataHooks.customizeTipPicker}>
      <Text typography="p2-l">{t('cart_tip_leavetip_label')}</Text>
      <BoxSelection
        name="tip-presets"
        className={classes.root}
        onChange={({ id }: { id: string }) => {
          const selectedCustomAmount = id === 'custom-amount';
          const selectedTip = !selectedCustomAmount ? selectedTipPresets[Number(id)] : { value: 'custom' };
          const selectedTipValue = typeof selectedTip.value === 'string' ? 0 : selectedTip.value;

          setSelectedId(id);
          setIsCustomAmount(selectedCustomAmount);

          if (!selectedCustomAmount) {
            onTipChange({
              tip: {
                amount: isTipTypePercent ? selectedTipValue : selectedTipValue * 100,
                tipType: isTipTypePercent ? TipType.TipTypePercent : TipType.TipTypeCurrencyAmount,
                isCustom: false,
              },
            });
          } else {
            // resetting tip to 0 if we click on custom amount button
            onTipChange({
              tip: {
                amount: 0,
                tipType: TipType.TipTypeCurrencyAmount,
                isCustom: true,
              },
            });
            setCustomAmountInputValue(0);
          }
        }}
      >
        {getBoxSelectionOptions(selectedTipPresets, selectedId, selectedTipType, t, currency, tipPresets.customTips)}
      </BoxSelection>
      {isCustomAmount && (
        <div className={styles.customAmountInput}>
          <CurrencyInput
            value={customAmountInputValue}
            onChange={(value: number) => {
              onTipChange({
                tip: {
                  amount: value * 100,
                  tipType: TipType.TipTypeCurrencyAmount,
                  isCustom: true,
                },
              });
            }}
            dataHook={dataHooks.customizeTipPickerCustomAmountInput}
            locale={locale}
            currency={currencySymbol}
            label={t('cart_summary_tip')}
          />
        </div>
      )}
    </div>
  );
};

export default TipPicker;
