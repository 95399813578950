import { Address as MembersAddress } from '@wix/ambassador-addresses-web/types';
import {
  Address as OloAddress,
  countryCodeIso3Map,
  get as getProperties,
  set as setProperties,
} from '@wix/restaurants-client-logic';

export function convertMembersAddressToOloAddress(address: MembersAddress): OloAddress {
  if (!address.addressLine1) {
    throw new Error('addressLine1 is a required field');
  }
  const res = {
    latLng: { lat: address.location?.latitude!, lng: address.location?.longitude! },
    formatted: address.addressLine1,
    addressLine2: extractAddressLine2FromMembersAddress(address),
    comment: address.hint,
    street: address.street?.name,
    number: address.street?.number,
    city: address.city,
    countryCode: Object.keys(countryCodeIso3Map).find((key) => countryCodeIso3Map[key] === address.country),
    postalCode: address.zipCode,
    label: address.type,
  };
  if (address.subdivision) {
    setProperties(res, 'subdivision', address.subdivision);
  }
  return res;
}

export function convertToOloAddressMembersAddress(address: OloAddress): MembersAddress {
  const membersAddress: MembersAddress = {
    fullName: {
      firstName: '_',
      lastName: '_',
    },
    addressLine1: address.formatted,
    addressLine2: address.addressLine2,
    location: { latitude: address.latLng.lat, longitude: address.latLng.lng },
    hint: address.comment,
    city: address.city,
    country: address.countryCode ? countryCodeIso3Map[address.countryCode] : undefined,
    zipCode: address.postalCode,
    type: address.label,
    subdivision: getProperties(address, 'subdivision'),
  };

  if (address.street || address.number) {
    membersAddress.street = {
      name: address.street || '',
      number: address.number || '',
    };
  }

  return membersAddress;
}

export function getUpdateAddressObjectPaths(address: Record<string, any>, prefix: string = ''): string[] {
  const result: string[] = [];

  Object.keys(address).forEach((key) => {
    if (typeof address[key] === 'object') {
      result.push(...getUpdateAddressObjectPaths(address[key], key + '.'));
    } else if (typeof address[key] !== 'undefined') {
      result.push(prefix + key);
    }
  });

  return result;
}

export function extractAddressLine2FromMembersAddress(address: MembersAddress): string | undefined {
  const fields = [address.city, address.subdivision, address.country];

  if (address.addressLine1) {
    const isAddressFormatedIncludesZipCode = address.zipCode
      ?.split('-')
      .find((str) => address.addressLine1?.includes(str));

    !isAddressFormatedIncludesZipCode && fields.push(address.zipCode);
  } else {
    fields.push(address.zipCode);
  }

  const filteredAddressLine2 = fields
    .filter((str) => str && (!address.addressLine1 || !address.addressLine1.includes(str)))
    .join(', ');

  return address.addressLine2 || filteredAddressLine2 || undefined;
}
