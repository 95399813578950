import React from 'react';
import dataHooks from '../../data-hooks';

export interface CartDiscountIconWrapperProps {
  iconUrl: string;
  artboardSize: number;
  iconSize: number;
}

const CartDiscountIconWrapper: React.FC<CartDiscountIconWrapperProps> = ({ iconUrl, iconSize, artboardSize }) => {
  return (
    <div
      style={{
        width: artboardSize,
        height: artboardSize,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        data-hook={dataHooks.cartDiscountImage}
        src={`https://static.wixstatic.com/${iconUrl}`}
        width={iconSize}
        height={iconSize}
        alt=""
      />
    </div>
  );
};

CartDiscountIconWrapper.displayName = 'CartDiscountIconWrapper';

export default CartDiscountIconWrapper;
