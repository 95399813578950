import { CalculatedFee, CalculateServiceFeesRequest, Type } from '@wix/ambassador-service-fees-rules/types';
import { ServiceFeesRules } from '@wix/ambassador-service-fees-rules/http';
import { VirtualDispatchType } from '@wix/restaurants-client-logic/dist/types/types/Dispatch';
import { Experiments } from '@wix/yoshi-flow-editor';
import { SetIsCalculatingServiceFeesPayload } from '../state/checkout/checkout.actions.types';
import { RestaurantLocation } from '@wix/ambassador-restaurant-locations/types';
import { setHasServiceFeesRules } from '../state/session/session.actions';
import { restaurantsOloUouServiceFeeFailureWeb } from '@wix/bi-logger-olo-client/v2';

const CALC_FEES_REQUEST_LABEL = 'restaurants';
export type UpdateCalculatedFeesParams = {
  experiments: Experiments;
  locationId?: string;
  isCurbside?: boolean;
  subtotal: number;
  fedopsLogger: any;
  biLogger: any;
  dispatch: VirtualDispatchType;
  setIsCalculatingServiceFees?: (payload: SetIsCalculatingServiceFeesPayload) => void;
};

const getShippingInfoType = (dispatchType: VirtualDispatchType, isCurbside?: boolean) => {
  if (isCurbside) {
    return Type.CURBSIDE_PICKUP;
  }

  switch (dispatchType) {
    case 'delivery':
      return Type.DELIVERY;
    case 'dine-in':
      return Type.DINE_IN;
    case 'takeout':
      return Type.PICKUP;
    default:
      return Type.UNSPECIFIED_FULFILLMENT_TYPE;
  }
};

export const getRestLocationId = ({
  locationId,
  restaurantLocations,
}: {
  locationId?: string;
  restaurantLocations: RestaurantLocation[];
}) => {
  const restaurantLocation = restaurantLocations.find(
    (restLocation) => restLocation.location?.locationId === locationId,
  );
  return restaurantLocation?.id;
};

export const createCalcFeesRequest = ({
  currency,
  isCurbside,
  subtotal,
  locale,
  isMobile,
  dispatch,
  locationId,
  restaurantLocations,
}: {
  currency: string;
  isCurbside?: boolean;
  subtotal: number;
  locale: string;
  isMobile: boolean;
  dispatch: VirtualDispatchType;
  locationId?: string;
  restaurantLocations: RestaurantLocation[];
}): CalculateServiceFeesRequest => {
  const shippingInfoType = getShippingInfoType(dispatch, isCurbside);
  const localeObj = locale.split('_');
  const restLocationId = getRestLocationId({ locationId, restaurantLocations });

  return {
    label: CALC_FEES_REQUEST_LABEL,
    order: {
      locale: {
        country: localeObj[1],
        languageCode: localeObj[0],
      },
      currency,
      locationId: restLocationId,
      platform: {
        value: isMobile ? 'MOBILE_SITE' : 'SITE',
      },
      priceSummary: {
        subtotal: (subtotal / 100).toFixed(2),
      },
      shippingInfo: {
        logistics: {
          type: shippingInfoType,
        },
      },
    },
  };
};

export const updateCalculatedFeesDecorator = ({
  isMobile,
  signedInstance,
  locale,
  currency,
  restaurantLocations,
  hasServiceFeesRules,
}: {
  isMobile: boolean;
  signedInstance?: string;
  currency: string;
  locale: string;
  restaurantLocations: RestaurantLocation[];
  hasServiceFeesRules?: boolean;
}) => {
  return async ({
    experiments,
    locationId,
    isCurbside,
    subtotal,
    fedopsLogger,
    biLogger,
    setIsCalculatingServiceFees,
    dispatch,
  }: UpdateCalculatedFeesParams): Promise<{
    calculatedFees?: CalculatedFee[];
    calcServiceFeesFailed?: boolean;
  }> =>
    updateCalculatedFees({
      experiments,
      locationId,
      currency,
      locale,
      restaurantLocations,
      signedInstance,
      isMobile,
      isCurbside,
      subtotal,
      fedopsLogger,
      biLogger,
      dispatch,
      setIsCalculatingServiceFees,
      hasServiceFeesRules,
    });
};

export const updateCalculatedFees = async ({
  experiments,
  signedInstance,
  currency,
  locationId,
  isCurbside,
  subtotal,
  fedopsLogger,
  biLogger,
  setIsCalculatingServiceFees,
  locale,
  isMobile,
  dispatch,
  restaurantLocations,
  hasServiceFeesRules,
}: {
  experiments: Experiments;
  signedInstance?: string;
  currency: string;
  locale: string;
  locationId?: string;
  isCurbside?: boolean;
  isMobile: boolean;
  subtotal: number;
  fedopsLogger: any;
  biLogger?: any;
  dispatch: VirtualDispatchType;
  setIsCalculatingServiceFees?: (payload: SetIsCalculatingServiceFeesPayload) => void;
  restaurantLocations: RestaurantLocation[];
  hasServiceFeesRules?: boolean;
}): Promise<{ calculatedFees?: CalculatedFee[]; calcServiceFeesFailed?: boolean }> => {
  const result: {
    calculatedFees?: CalculatedFee[];
    calcServiceFeesFailed?: boolean;
  } = {
    calculatedFees: undefined,
    calcServiceFeesFailed: false,
  };
  const isServiceFeeExperimentEnabled = experiments.enabled('specs.restaurants.isServiceFeeEnabled-olo-client-dev');
  if (!isServiceFeeExperimentEnabled || !hasServiceFeesRules) {
    return {};
  }
  const headers = { Authorization: signedInstance };
  const calculateServiceFeesApi = ServiceFeesRules('/_api/service-fees').ServiceFeesCalculate()(headers);
  const request = createCalcFeesRequest({
    dispatch,
    isMobile,
    subtotal,
    isCurbside,
    currency,
    locationId,
    locale,
    restaurantLocations,
  });

  try {
    setIsCalculatingServiceFees && setIsCalculatingServiceFees({ isCalculating: true });

    fedopsLogger.interactionStarted('calculate-service-fees');
    const { calculatedFees } = await calculateServiceFeesApi.calculateServiceFees(request);
    fedopsLogger.interactionEnded('calculate-service-fees');
    setIsCalculatingServiceFees && setIsCalculatingServiceFees({ isCalculating: false });

    result.calculatedFees = calculatedFees;
  } catch (e) {
    setIsCalculatingServiceFees && setIsCalculatingServiceFees({ isCalculating: false });
    biLogger &&
      biLogger.report(
        restaurantsOloUouServiceFeeFailureWeb({
          requestName: 'calculateServiceFees',
          error: JSON.stringify(e),
        } as any),
      );

    result.calcServiceFeesFailed = true;
  }
  return result;
};

export const checkIfHasServiceFeesRules = async ({
  signedInstance,
  locationId,
  fedopsLogger,
  biLogger,
  restaurantLocations,
  dispatch,
}: {
  signedInstance?: string;
  locationId?: string;
  fedopsLogger: any;
  biLogger?: any;
  restaurantLocations: RestaurantLocation[];
  dispatch: Function;
}) => {
  const headers = { Authorization: signedInstance };
  const serviceFeesApi = ServiceFeesRules('/_api/service-fees').ServiceFeesRules()(headers);
  const request = {
    label: 'restaurants',
  };
  try {
    fedopsLogger.interactionStarted('list-service-fees-rules');
    const response = await serviceFeesApi.listRules(request);
    fedopsLogger.interactionEnded('list-service-fees-rules');

    if (response?.rules?.length) {
      dispatch(setHasServiceFeesRules({ hasServiceFeesRules: true }));
    }
  } catch (e) {
    console.error('Could not get list service fees rules', e);
    biLogger &&
      biLogger.report(
        restaurantsOloUouServiceFeeFailureWeb({
          requestName: 'listRules',
          error: JSON.stringify(e),
        } as any),
      );
  }
};
