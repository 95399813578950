import { AppState } from '../createStore';
import { createSelector } from 'reselect';

import { DispatchInfo, isDeliveryPartnerArea, getAvailableDeliveryInfos } from '@wix/restaurants-client-logic/';
import _ from 'lodash';

function deliveryPartnerFeeSelectorInner(
  dispatchInfos: DispatchInfo[],
  timezone: string,

  deliveryPartnerFee?: number,
) {
  const deliveryProviderArea: DispatchInfo | undefined = dispatchInfos.find(
    (info) => isDeliveryPartnerArea(info) && getAvailableDeliveryInfos([info], timezone).length > 0,
  );
  const subsidyFee = deliveryProviderArea
    ? _.get(deliveryProviderArea, 'deliveryProviderInfo.restaurantSubsidyFee', 0)
    : 0;
  return deliveryPartnerFee !== undefined ? Math.max(0, deliveryPartnerFee - subsidyFee) : undefined;
}

export const deliveryPartnerFeeSelector = createSelector(
  [
    (state: AppState) => state.session.restaurant.deliveryInfos,
    (state: AppState) => state.session.restaurant.timezone,
    (state: AppState) => state.checkout.deliveryProvider?.fee,
  ],
  deliveryPartnerFeeSelectorInner,
);
