import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import { bindActionCreators, Dispatch } from 'redux';
import { cartSummarySelector } from '../../../../state/selectors/cartSummarySelector';
import CartSubtotal from './CartSubtotal';
import { setCalculatedFees } from '../../../../state/checkout/checkout.actions';
import { getVirtualDispatchTypeFromDispatch } from '../../../../core/logic/dispatchLogic';
import { updateCalculatedFeesDecorator } from '../../../../core/calcServiceFees';
import { getCalculatedFeesParamsSelector } from '../../../../state/selectors/calculatedFeesSelector';

function mapStateToProps(state: AppState, ownProps: any) {
  const { priceComponents } = cartSummarySelector(state);
  const calculatedFeesParams = getCalculatedFeesParamsSelector(state);

  return {
    dispatchType: getVirtualDispatchTypeFromDispatch(state.checkout.dispatch),
    locationId: state.session.restaurant.currentLocationId,
    priceComponents,
    isCurbside: state.checkout.dispatch.type === 'takeout' && state.checkout.dispatch.isCurbside,
    updateCalculatedFees: updateCalculatedFeesDecorator(calculatedFeesParams),
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setCalculatedFees,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CartSubtotal);
