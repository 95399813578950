import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import { setContactlessDelivery } from '../../../../state/checkout/checkout.actions';
import ContactlessDeliveryToggle from './ContactlessDeliveryToggle';

function mapStateToProps(state: AppState) {
  return {
    isContactlessDeliveryChecked: state.checkout.isContactlessDeliveryChecked,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ setContactlessDelivery }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactlessDeliveryToggle);
