import CheckoutFlow from './CheckoutFlow';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import { businessNotificationSelectorByDispatchTime } from '../../../../state/selectors/businessNotificationSelector';
import {
  loginMember,
  logoutMember,
  setCheckoutStep,
  setIsFetchingEstimateFromTPA,
  submitOrder,
} from '../../../../state/checkout/checkout.actions';
import { BusinessNotifications, Contact, EMPTY_ADDRESS } from '@wix/restaurants-client-logic';
import { getOrderPolicy } from '../../../../core/logic/policyLogic';
import { closeModal, openModal } from '../../../../state/session/session.actions';
import { cartSummarySelector } from '../../../../state/selectors/cartSummarySelector';
import { loyaltyProgramSelector } from '../../../../state/selectors/loyaltyProgramSelector';

function mapStateToProps(state: AppState) {
  const businessNotification = businessNotificationSelectorByDispatchTime(state)?.notification;
  const { displayableOrderItems, priceComponents } = cartSummarySelector(state);
  const shouldGoBack =
    businessNotification === BusinessNotifications.OlOunavailable ||
    businessNotification === BusinessNotifications.RestaurantClosed ||
    (!state.checkout.orderResponse && displayableOrderItems.length === 0);
  const { pointsRequired, currencyValue, customPointsName, pointsBalance, isLoyaltyEarnActive } =
    loyaltyProgramSelector(state);

  return {
    orderPolicy: getOrderPolicy(state.session.restaurant),
    paymentMethod: state.checkout.pendingCashierPayment.paymentMethod,
    contact: state.checkout.contact,
    dispatchType: state.checkout.dispatch.type,
    isMobile: state.platformParams.isMobile,
    siteIsTemplate: state.platformParams?.instance?.siteIsTemplate,
    shouldGoBack,
    step: state.checkout.checkoutStep,
    isContactlessDeliveryChecked: state.checkout.isContactlessDeliveryChecked,
    initialContact: getInitialContact(state),
    isMemberContactLoading: state.checkout.isMemberContactLoading,
    isUserLoggedIn: state.session.isUserLoggedIn,
    loggedInUserEmail: state.session.loggedInUserEmail,
    hasMembersAreaIntegration: state.platformParams.settings.hasMembersAreaIntegration,
    isMAInstalled: state.session.isMAInstalled,
    savedAddresses: state.session.savedAddresses,
    address: state.checkout.dispatch.type === 'delivery' ? state.checkout.dispatch.address : EMPTY_ADDRESS,
    selectedAddressOption: state.addressForm.selectedAddressOption,
    pointsRequired,
    currencyValue,
    pointsBalance,
    centsAmount: priceComponents.subtotal,
    customPointsName,
    isLoyaltyEarnActive,
    isMultiLocation: state.session.isMultiLocation,
    earningRules: state.session.loyaltyEarningRules || [],
  };
}

function getInitialContact(state: AppState): Contact {
  const { contact, membersApiContact } = state.checkout;
  const { isMAInstalled } = state.session;

  if (!state.platformParams.settings.hasMembersAreaIntegration || !isMAInstalled) {
    return contact;
  }
  if (contact.firstName || contact.lastName || contact.phone) {
    if (membersApiContact.emails) {
      contact.email = membersApiContact.emails[0];
    }
    return contact;
  }
  const { firstName, lastName, emails = [], phones = [] } = membersApiContact;
  return {
    ...contact,
    firstName: firstName ? firstName : '',
    lastName: lastName ? lastName : '',
    email: emails[0] || state.session.loggedInUserEmail || '',
    phone: phones[0] || '',
  };
}
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setStep: setCheckoutStep,
      closeModal,
      loginMember,
      logoutMember,
      openModal,
      setIsFetchingEstimateFromTPA,
      submitOrder,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutFlow);
