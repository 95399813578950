import { DisplayableMenu, DisplayableMenuItem } from '@wix/restaurants-client-logic';

export const hasItemImages = (displayableMenu: DisplayableMenu) => {
  let res = false;
  displayableMenu.map((displayableSubMenu) => {
    displayableSubMenu.sections.map((displayableSection) => {
      const hasImages = (displayableMenuItem: DisplayableMenuItem) => displayableMenuItem.image !== undefined;
      if (displayableSection.items.some(hasImages)) {
        res = true;
      }
    });
  });
  return res;
};
