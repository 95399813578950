import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import { closeModal, openModal } from '../../../../state/session/session.actions';
import { createNewPendingOrderItem, openExistingOrderItem } from '../../../../state/cart/cart.actions';
import { clearOrderResponse } from '../../../../state/checkout/checkout.actions';
import Routes from './Routes';

function mapStateToProps(state: AppState) {
  return {
    routeUrlOverride: state.session.routeUrlOverride,
    itemsHash: state.session.itemsHash,
    orderItemsCount: state.cart.orderItems.length,
    isMultiLocation: state.session.isMultiLocation,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      openModal,
      closeModal,
      createNewPendingOrderItem,
      clearOrderResponse,
      openExistingOrderItem,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
