import React, { useEffect, useCallback } from 'react';
import _ from 'lodash';
import styles from './CartSubtotal.scss';
import Text from '../../core-components/Text';
import dataHooks from '../../data-hooks';
import { useTranslation, useFedopsLogger, useExperiments, useBi } from '@wix/yoshi-flow-editor';
import { ServiceFee, PriceComponents, VirtualDispatchType } from '@wix/restaurants-client-logic';
import CartTaxAndFees from '../CartTaxAndFees';
import { UpdateCalculatedFeesParams } from '../../../../core/calcServiceFees';
import { CalculatedFee } from '@wix/ambassador-service-fees-rules/types';
import { SetCalculatedFeesPayload } from '../../../../state/checkout/checkout.actions.types';

export interface CartSubtotalProps {
  subtotal?: string;
  tax?: string;
  serviceFees?: ServiceFee[];
  taxesAndServiceFeesTotalAmount?: string;
  deliveryFee?: string;
  tip?: string;
  total?: string;
  showTotal?: boolean;
  isEstimatedDeliveryFee?: boolean;
  minOrderPrice?: string;
  setCalculatedFees: (payload: SetCalculatedFeesPayload) => void;
  priceComponents: PriceComponents;
  locale: string;
  currency: string;
  isMobile: boolean;
  dispatchType: VirtualDispatchType;
  locationId?: string;
  isCurbside: boolean;
  signedInstance?: string;
  updateCalculatedFees: (
    params: UpdateCalculatedFeesParams,
  ) => Promise<{ calculatedFees?: CalculatedFee[]; calcServiceFeesFailed?: boolean }>;
}

const CartSubtotal: React.FC<CartSubtotalProps> = ({
  subtotal,
  tax,
  serviceFees,
  taxesAndServiceFeesTotalAmount,
  deliveryFee,
  tip,
  total,
  showTotal,
  isEstimatedDeliveryFee,
  minOrderPrice,
  setCalculatedFees,
  priceComponents,
  dispatchType,
  locationId,
  isCurbside,
  updateCalculatedFees,
}) => {
  const { t } = useTranslation();
  const biLogger = useBi();
  const { experiments } = useExperiments();
  const fedopsLogger = useFedopsLogger();
  const shouldRenderDeliveryFee = deliveryFee || isEstimatedDeliveryFee;
  const shouldRenderTaxAndFees = tax || serviceFees?.length;

  useEffect(() => {
    debouncedCalculateServiceFees(priceComponents.subtotal);
  }, [subtotal]);

  const calculateServiceFees = _.debounce(async (orderSubtotal: number) => {
    const { calculatedFees, calcServiceFeesFailed } = await updateCalculatedFees({
      fedopsLogger,
      biLogger,
      subtotal: orderSubtotal,
      experiments,
      locationId,
      isCurbside,
      dispatch: dispatchType,
    });
    if (!calcServiceFeesFailed && calculatedFees !== undefined) {
      setCalculatedFees({ calculatedFees });
    }
  }, 1000);

  const debouncedCalculateServiceFees = useCallback(calculateServiceFees, []);

  return (
    <div className={styles.wrapper} data-hook={dataHooks.cartSubtotalWrapper}>
      <div className={minOrderPrice ? styles.rowMinPrice : styles.row}>
        <Text typography="p2-m" className={styles.title}>
          {t('cart_summary_subtotal')}
        </Text>
        <Text typography="p2-m" className={styles.price} data-hook={dataHooks.cartSubtotalSubtotal}>
          {subtotal}
        </Text>
      </div>
      {Boolean(minOrderPrice) && (
        <div className={styles.row}>
          <Text data-hook={dataHooks.cartSubtotalMinOrder} typography="p2-s-secondary">
            {t('Order_Online_MinimumOrder_Amount_Label', { amount: minOrderPrice })}
          </Text>
        </div>
      )}
      {shouldRenderTaxAndFees ? (
        <CartTaxAndFees
          tax={tax}
          serviceFees={serviceFees}
          taxesAndServiceFeesTotalAmount={taxesAndServiceFeesTotalAmount}
        />
      ) : null}
      {shouldRenderDeliveryFee && (
        <div className={styles.row}>
          <Text typography="p2-m" className={styles.title}>
            {t('checkout_main_order_delivery_fee')}
          </Text>
          <Text typography="p2-m" className={styles.price}>
            {isEstimatedDeliveryFee ? (
              <Text data-hook={dataHooks.cartSubtotalDeliveryFeeFromText} typography="p2-s-secondary">
                {t('checkout_main_order_delivery_fee_varies_label')}
              </Text>
            ) : (
              <span data-hook={dataHooks.cartSubtotalDeliveryFee}>{deliveryFee}</span>
            )}
          </Text>
        </div>
      )}

      {tip && (
        <div className={styles.row}>
          <Text typography="p2-m" className={styles.title}>
            {t('cart_summary_tip')}
          </Text>
          <Text typography="p2-m" className={styles.price} data-hook={dataHooks.cartSubtotalTip}>
            {tip}
          </Text>
        </div>
      )}

      {showTotal && (
        <div className={`${styles.row} ${styles.total}`}>
          <Text typography="p2-l" className={styles.title}>
            {t('cart_summary_total')}
          </Text>
          <Text typography="p2-l" className={styles.price} data-hook={dataHooks.cartSubtotalTotal}>
            {total}
          </Text>
        </div>
      )}
    </div>
  );
};

CartSubtotal.displayName = 'CartSubtotal';

export default CartSubtotal;
