import React from 'react';

export interface CartIconProps {
  className?: string;
  'data-hook'?: string;
}

const CartIcon: React.FC<CartIconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={props.className}
      data-hook={props['data-hook']}
    >
      <g fill="none">
        <g stroke="currentColor">
          <path d="M15.4524836,4.5 L14.5433927,14.5 L1.45660732,14.5 L0.54751641,4.5 L15.4524836,4.5 Z" />
          <path d="M5,4 C5,3.83296441 5,3.53407118 5,3.2 C5,1.4326888 6.34314575,0 8,0 C9.65685425,0 11,1.4326888 11,3.2 C11,3.54440104 11,3.82779948 11,4" />
        </g>
      </g>
    </svg>
  );
};

CartIcon.displayName = 'CartIcon';

export default CartIcon;
