import { AppState } from '../createStore';
import { cartSummarySelector } from './cartSummarySelector';
import { createSelector } from 'reselect';
import {
  ChargeV2,
  Contact,
  CouponSuccess,
  Dispatch,
  Order,
  OrderItem,
  Payment,
  Restaurant,
  createOrderLoyaltyObject,
} from '@wix/restaurants-client-logic';
import { getDineInInfo } from '../../core/logic/dispatchLogic';
import { DineInInfo } from '@wix/restaurants-client-logic/dist/types/types/Restaurant';
import { PickupDispatch } from '@wix/restaurants-client-logic/dist/types/types/Dispatch';
import { loyaltyProgramSelector } from './loyaltyProgramSelector';

const getTakeoutDispatch = (delivery: PickupDispatch, dineInInfo?: DineInInfo) => {
  const takeOutDispatch: PickupDispatch = {
    type: 'takeout',
  };
  if (delivery.contactlessDineIn) {
    takeOutDispatch.contactlessDineIn = {
      labelValue: delivery.contactlessDineIn.labelValue,
      label: dineInInfo?.label || '',
    };
  } else {
    takeOutDispatch.time = delivery.time;
    takeOutDispatch.isCurbside = delivery.isCurbside;
    takeOutDispatch.curbsideAdditionalInformation = delivery.curbsideAdditionalInformation;
  }
  return takeOutDispatch;
};

export const getDelivery = (delivery: Dispatch, deliveryFee?: number, dineInInfo?: DineInInfo): Dispatch => {
  switch (delivery.type) {
    case 'delivery':
      return {
        ...delivery,
        charge: deliveryFee,
      };

    case 'premises':
      return {
        type: 'premises',
        comment: delivery.comment,
      };
    case 'takeout':
    default:
      return getTakeoutDispatch(delivery, dineInInfo);
  }
};

export function findTipCharge(chargesV2: ChargeV2[] = []) {
  return chargesV2.find((charge) => charge.type === 'tip' && charge.state === 'operational');
}

export function getTipPresets(restaurant: Restaurant) {
  const tip = restaurant.orders.properties?.tip;
  return tip ? JSON.parse(tip) : {};
}

function orderSelectorInner(
  orderItems: OrderItem[],
  payments: Payment[],
  restaurant: Restaurant,
  contact: Contact,
  dispatch: Dispatch,
  cartSummary: ReturnType<typeof cartSummarySelector>,
  loyaltyProgram: ReturnType<typeof loyaltyProgramSelector>,
  coupon?: CouponSuccess,
  comment?: string,
  isContactlessDeliveryChecked?: boolean,
  msid?: string,
  isMobile?: boolean,
) {
  const { orderCharges, priceComponents } = cartSummary;

  const order: Order = {
    restaurantId: restaurant.id,
    locale: restaurant.locale,
    orderItems,
    comment: comment || '',
    price: priceComponents.total,
    currency: restaurant.currency,
    delivery: getDelivery(dispatch, priceComponents.deliveryFee, getDineInInfo(restaurant)),
    contact,
    payments: payments.length ? [{ ...payments[0], amount: priceComponents.total }] : [],
    orderCharges,
    status: 'new',
    developer: 'com.wix.restaurants',
    platform: isMobile ? 'mobileweb' : 'web',
    source: '',
    coupon: coupon && {
      token: coupon.token,
      code: coupon.code,
    },
    properties: isContactlessDeliveryChecked ? { 'wixrest.olo': '{"contactlessDelivery": true}' } : undefined,
    externalIds: msid ? { msid } : undefined,
  };

  if (loyaltyProgram.isLoyaltyEarnActive || loyaltyProgram.isLoyaltyRedeemActive) {
    const {
      loyaltyChargesV2,
      currencyValue,
      loyaltyRewardId = '',
      pointsBalance,
      pointsRequired,
      loyaltyEarningRules,
      loyaltyRewardRevision,
    } = loyaltyProgram;

    order.loyalty = createOrderLoyaltyObject({
      chargesV2: loyaltyChargesV2,
      currencyValue,
      loyaltyRewardId,
      orderCharges,
      pointsBalance,
      pointsRequired,
      subtotal: priceComponents.subtotal,
      earningRules: loyaltyEarningRules,
      loyaltyRewardRevision,
    });
  }

  return order;
}

export const orderSelector = createSelector(
  [
    (state: AppState) => state.cart.orderItems,
    (state: AppState) => state.checkout.payments,
    (state: AppState) => state.session.restaurant,
    (state: AppState) => state.checkout.contact,
    (state: AppState) => state.checkout.dispatch,
    (state: AppState) => cartSummarySelector(state),
    (state: AppState) => loyaltyProgramSelector(state),
    (state: AppState) => (state.cart.coupon?.type === 'success' && state.cart.coupon) || undefined,
    (state: AppState) => state.cart.comment,
    (state: AppState) => state.checkout.isContactlessDeliveryChecked,
    (state: AppState) => state.platformParams.instance.metaSiteId,
    (state: AppState) => state.platformParams.isMobile,
  ],
  orderSelectorInner,
);
