import React from 'react';
import { SetContactFieldPayload } from '../../../../state/checkout/checkout.actions.types';
import ContactInformationInput, { ContactInformationInputProps } from './ContactInformationInput';

interface PhoneNumberInputProps extends ContactInformationInputProps {
  onChange: (payload: SetContactFieldPayload) => void;
}

const ContactPhoneNumberInput: React.FC<PhoneNumberInputProps> = (props) => {
  const onChangeInput = ({ field, value }: SetContactFieldPayload) => {
    // if input contains only digits or '+' or '-' -> call "real" onChange
    if (/^(?:[+\-\d]*)$/.test(value)) {
      props.onChange({ field, value });
    }
  };

  return <ContactInformationInput {...props} onChange={onChangeInput} />;
};

export default ContactPhoneNumberInput;
