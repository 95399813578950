import _ from 'lodash';
import { PaymentsWidgetAPI, ServerPaymentStatus, InitializePaymentResult } from '@wix/cashier-payments-widget';
import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  Address,
  Contact,
  DispatchType,
  Order,
  countryCodeIso3Map,
  get as getProperties,
} from '@wix/restaurants-client-logic';

let cashierApi: PaymentsWidgetAPI;
let wrapperPromiseResolve: (value?: unknown) => void;

const apiPromise = new Promise((resolve) => {
  wrapperPromiseResolve = resolve;
});

export function setCashierApi(api: PaymentsWidgetAPI) {
  cashierApi = api;
  wrapperPromiseResolve();
}

export function getCashierApi(): Promise<PaymentsWidgetAPI> {
  return apiPromise.then(() => cashierApi);
}

export function extractCashierResponse(order: Order): { responseToken: string; status: string } {
  const cashierResponse = _.find(order.payments?.map((p) => p?.externalIds?.cashier)) || '{}';
  return JSON.parse(cashierResponse);
}

export interface NotValidError {
  error: 'not-valid';
  invalidFields: string[];
}

export interface ApiUnavailable {
  error: 'initializePayment-unavailable';
}

type OnSuccess = (result: InitializePaymentResult) => void;
type OnFailure = (error: NotValidError | ApiUnavailable) => void;

export async function validateAndInitializePayment(onSuccess: OnSuccess, onFailure: OnFailure) {
  const paymentsWidgetAPI = await getCashierApi();

  try {
    const { isValid, invalidFields } = await paymentsWidgetAPI.validate();

    if (!isValid && invalidFields) {
      onFailure({ error: 'not-valid', invalidFields });
    } else if (!paymentsWidgetAPI.initializePayment) {
      onFailure({ error: 'initializePayment-unavailable' });
    } else {
      const { detailsId, cardPublicData } = await paymentsWidgetAPI.initializePayment();
      onSuccess({ detailsId, cardPublicData });
    }
  } catch (e) {
    onFailure({ error: 'initializePayment-unavailable' });
  }
}

export function createMandatoryFieldsObject({
  contact,
  dispatchType,
  address,
  paymentMethod,
}: {
  contact: Contact;
  dispatchType: DispatchType;
  address?: Address;
  paymentMethod?: string;
}): _.Dictionary<string | undefined> {
  const { email, firstName, lastName, phone } = contact;
  const { countryCode, formatted, number, street, city, postalCode } = address || {};

  const result: _.Dictionary<string | undefined> = {
    email,
  };

  if (paymentMethod !== 'offline') {
    result.firstName = firstName;
    result.lastName = lastName;
    result.phone = phone;

    if (dispatchType === 'delivery') {
      result.countryCode = countryCode && countryCodeIso3Map[countryCode];
      result.address = formatted;
      result.houseNumber = number;
      result.street = street;
      result.city = city;
      result.zipCode = postalCode;
      result.state = getProperties(address, 'subdivision');
    } else {
      result.countryCode = '';
      result.address = '';
      result.houseNumber = '';
      result.street = '';
      result.city = '';
      result.zipCode = '';
      result.state = '';
    }
  }

  return _.omitBy(result, (value) => _.isUndefined(value) || _.isNull(value));
}

export function isCashierPendingServerStatus(serverStatus: ServerPaymentStatus): boolean {
  return [
    ServerPaymentStatus.PendingMerchant,
    ServerPaymentStatus.PendingBuyer,
    ServerPaymentStatus.Initialized,
    ServerPaymentStatus.Offline,
  ].includes(serverStatus);
}

export function isCashierApprovedServerStatus(serverStatus: ServerPaymentStatus): boolean {
  return serverStatus === ServerPaymentStatus.Approved;
}

export function isCashierInProcessServerStatus(serverStatus: ServerPaymentStatus): boolean {
  return [ServerPaymentStatus.InProcess, ServerPaymentStatus.Initialization].includes(serverStatus);
}

export function isCashierFailedServerStatus(serverStatus: ServerPaymentStatus): boolean {
  return [
    ServerPaymentStatus.Undefined,
    ServerPaymentStatus.Declined,
    ServerPaymentStatus.Failed,
    ServerPaymentStatus.BuyerCanceled,
    ServerPaymentStatus.Timeout,
  ].includes(serverStatus);
}

function getPageUrl(flowAPI: ControllerFlowAPI) {
  return new URL(
    flowAPI.controllerConfig.wixCodeApi.location.url || flowAPI.controllerConfig.wixCodeApi.location.baseUrl,
  );
}

export function parseCashierCallbackParams(flowAPI: ControllerFlowAPI) {
  try {
    const pageUrl = getPageUrl(flowAPI);
    const search = new URLSearchParams(pageUrl.search.slice(1));

    return {
      isCashierCallback: search.get('cashierCallback') === 'true',
      status: search.get('transactionStatus'),
      restaurantId: search.get('restaurantsId'),
      orderId: search.get('orderId'),
      ownerToken: search.get('ownerToken'),
    };
  } catch (err) {
    console.error('[parseCashierCallbackParams]', err);
    return {};
  }
}

export function getCashierCallbackUrl(flowAPI: ControllerFlowAPI) {
  const pageUrl = getPageUrl(flowAPI);
  const search = new URLSearchParams(pageUrl.search.slice(1));
  search.append('cashierCallback', 'true');
  pageUrl.search = search.toString();
  return pageUrl.toString();
}

export async function continuePayment(responseToken: string) {
  const api = await getCashierApi();
  return api.continuePayment && api.continuePayment(responseToken);
}

export function clearCashierCallbackQueryParams(search: string) {
  const urlSearchParams = new URLSearchParams(search);
  urlSearchParams.delete('cashierCallback');
  urlSearchParams.delete('orderId');
  urlSearchParams.delete('ownerToken');
  urlSearchParams.delete('restaurantsId');
  urlSearchParams.delete('transactionStatus');
  const resultStr = urlSearchParams.toString();
  return (resultStr.length > 0 ? '?' : '') + resultStr;
}
