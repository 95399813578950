import React from 'react';
import styles from './CheckoutPaymentsSummary.scss';
import Text from '../../core-components/Text';
import dataHooks from '../../data-hooks';
import { CashierPayment, Payment } from '@wix/restaurants-client-logic';

export interface CheckoutPaymentsSummaryProps {
  payments: Payment[];
}

function isCashierPayment(payment: any): payment is CashierPayment {
  return payment && typeof payment.paymentMethodTitle === 'string';
}

const CheckoutPaymentsSummary: React.FC<CheckoutPaymentsSummaryProps> = ({ payments }) => {
  if (!isCashierPayment(payments[0])) {
    return null;
  }

  const payment: CashierPayment = payments[0];

  return (
    <div className={styles.summaryWrapper}>
      <Text
        typography="p2-m-secondary"
        className={styles.summaryLine}
        data-hook={dataHooks.checkoutPaymentsSummaryLine}
      >
        {payment.paymentMethodTitle}
      </Text>

      {payment.creditCard && (
        <Text
          typography="p2-m-secondary"
          className={styles.summaryLine}
          data-hook={dataHooks.checkoutPaymentsSummaryLine}
        >
          {payment.creditCard.network} ending in {payment.creditCard.lastDigits}
        </Text>
      )}
    </div>
  );
};

CheckoutPaymentsSummary.displayName = 'CheckoutPaymentsSummary';

export default CheckoutPaymentsSummary;
