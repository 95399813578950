import React from 'react';
import ModalLayoutEditor from '../ModalLayoutEditor';
import UpgradeToPremiumSvg from '../UpgradeToPremiumSvg';
import dataHooks from '../../data-hooks';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { editorUpgradeToPremium } from '@wix/bi-logger-olo-client/v2';

export interface UpgradeToPremiumModalProps {
  onRequestClose: () => void;
  instanceId: string;
  appDefId: string;
}

const UpgradeToPremiumModal: React.FC<UpgradeToPremiumModalProps> = ({ onRequestClose, instanceId, appDefId }) => {
  const biLogger = useBi();
  const { t } = useTranslation();

  return (
    <ModalLayoutEditor
      illustration={<UpgradeToPremiumSvg />}
      header={t('Editor_OOIModal_UpgradeToOrder_Title')}
      description={t('Editor_OOIModal_UpgradeToOrder_Text')}
      cta={t('Editor_OOIModal_UpgradeToOrder_CTA')}
      onCtaClick={() => {
        window.open(
          `https://premium.wix.com/wix/api/premiumStart?instanceId=${instanceId}&appDefId=${appDefId}&referralAdditionalInfo=restaurants_freemiumbar`,
        );
        biLogger.report(editorUpgradeToPremium({ origin: 'modal' }));
      }}
      footer={t('Editor_OOIModal_UpgradeToOrder_disclaimer')}
      onCloseClick={onRequestClose}
      data-hook={dataHooks.upgradeToPremiumModal}
      theme="premium"
    />
  );
};

UpgradeToPremiumModal.displayName = 'UpgradeToPremiumModal';

export default UpgradeToPremiumModal;
