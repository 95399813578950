import React from 'react';
import { TextArea, TextAreaProps } from 'wix-ui-tpa';
import { st, classes } from './FullWidthTextArea.st.css';

export interface FullWidthTextAreaProps extends TextAreaProps {}

const FullWidthTextArea: React.FC<FullWidthTextAreaProps> = (props) => {
  return <TextArea {...props} className={st(classes.root, {}, '')} />;
};

FullWidthTextArea.displayName = 'FullWidthTextArea';

export default FullWidthTextArea;
