import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import { bindActionCreators, Dispatch } from 'redux';
import { cartSummarySelector } from '../../../../state/selectors/cartSummarySelector';
import CartTitle from './CartTitle';

function mapStateToProps(state: AppState, ownProps: any) {
  const { itemCount } = cartSummarySelector(state);

  return {
    itemCount,
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CartTitle);
