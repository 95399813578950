import React, { useEffect, useState } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import SwitchTransition from 'react-transition-group/SwitchTransition';
import styles from './DrillDownComponent.scss';
import classNames from 'classnames';

export interface DrillDownComponentProps {
  primary: React.ReactElement;
  secondary: React.ReactElement;
  isDrilled?: boolean;
}

const { enter, enterActive, exit, exitActive } = styles;

const DrillDownComponent: React.FC<DrillDownComponentProps> = ({ isDrilled, primary, secondary }) => {
  const cls = { enter, enterActive, exit, exitActive };

  const [height, setHeight] = useState<number | undefined>(undefined);

  const ref = React.useRef<HTMLDivElement>(null);

  const timeout = parseInt(styles.duration, 10);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setHeight(ref.current?.offsetHeight);
    }, timeout * 1.5);

    return () => clearTimeout(timeoutId);
  }, [isDrilled, timeout]);

  return (
    <div className={classNames({ [styles.wrapper]: true, [styles.isDrilled]: isDrilled })} style={{ height }}>
      <div ref={ref}>
        <SwitchTransition>
          {isDrilled ? (
            <CSSTransition key="secondary" classNames={cls} timeout={timeout}>
              <div>{secondary}</div>
            </CSSTransition>
          ) : (
            <CSSTransition key="primary" classNames={cls} timeout={timeout}>
              <div>{primary}</div>
            </CSSTransition>
          )}
        </SwitchTransition>
      </div>
    </div>
  );
};

DrillDownComponent.displayName = 'DrillDownComponent';

export default DrillDownComponent;
