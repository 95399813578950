import React from 'react';
import { SectionNotification, ButtonPriority as PRIORITY, ButtonSize as SIZE } from 'wix-ui-tpa';
import dataHooks from '../../data-hooks';
import { isNoPhysicalLocation } from '../../../../state/selectors/businessNotificationSelector';
import { Address } from '@wix/restaurants-client-logic';
import styles from './NoBusinessAddressNotification.scss';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

export interface NoBusinessAddressNotificationProps {
  restaurantAddress: Address;
  metaSiteId: string;
}

function useEnvironmentSafe() {
  try {
    return useEnvironment();
  } catch (e) {
    return { isPreview: false };
  }
}

const NoBusinessAddressNotification: React.FC<NoBusinessAddressNotificationProps> = ({
  restaurantAddress,
  metaSiteId,
}) => {
  const { t } = useTranslation();
  const { isPreview } = useEnvironmentSafe();
  const shouldShowNoLocationWarning = isNoPhysicalLocation(restaurantAddress) && isPreview;
  return shouldShowNoLocationWarning ? (
    <SectionNotification
      type="alert"
      data-hook={dataHooks.noLocationNotificationBar}
      className={styles.notificationWrapper}
    >
      <SectionNotification.Text>{t('Online_ordering_addlocation_errormessage')}</SectionNotification.Text>
      <SectionNotification.Button
        type="default"
        key="primary"
        size={SIZE.tiny}
        priority={PRIORITY.basic}
        onClick={() => window.open(`https://www.wix.com/dashboard/${metaSiteId}/settings/?focus=address`)}
      >
        {t('Online_ordering_addlocation_CTA')}
      </SectionNotification.Button>
    </SectionNotification>
  ) : null;
};

NoBusinessAddressNotification.displayName = 'NoBusinessAddressNotification';

export default NoBusinessAddressNotification;
