import { useState, useCallback, useEffect } from 'react';
import { useFedopsLogger } from '@wix/yoshi-flow-editor';
import { BusyBlock } from '@wix/ambassador-restaurants-order-pacing-pacer/types';

import { useAppSelector } from './redux';
import { selectSignedInstance } from '../../state/selectors/platformSelector';
import { fetchOrderPacingBusyBlocks } from '../logic/orderPacingLogic';

interface useOrderPacingBusyBlocksParams {
  isEnabled?: boolean;
  locationId?: string;
  fromTimestamp: number | null;
  orderFulfillmentTimeInMinutes: number;
}

export default function useOrderPacingBusyBlocks({
  isEnabled,
  locationId,
  fromTimestamp,
  orderFulfillmentTimeInMinutes,
}: useOrderPacingBusyBlocksParams): BusyBlock[] {
  const fedopsLogger = useFedopsLogger();
  const [busyBlocks, setBusyBlocks] = useState<BusyBlock[]>([]);
  const signedInstance = useAppSelector((state) => selectSignedInstance(state));

  const fetchBusyBlocks = useCallback(async () => {
    if (signedInstance && fromTimestamp && orderFulfillmentTimeInMinutes && fetchOrderPacingBusyBlocks) {
      const busyBlocksResponse = await fetchOrderPacingBusyBlocks({
        signedInstance,
        locationId,
        fromTimestamp,
        orderFulfillmentTimeInMinutes,
        fedopsLogger,
      });

      setBusyBlocks(busyBlocksResponse);
    }
  }, [locationId, fromTimestamp]);

  useEffect(() => {
    isEnabled && fetchBusyBlocks();
  }, [locationId, fromTimestamp]);

  return busyBlocks;
}
