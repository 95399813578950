import React from 'react';
import { scroller, Element } from 'react-scroll';
import { calculateTopValue } from './scrollingUtils';

interface ScrollerProps {
  name: string;
  condition: boolean;
  children: React.ReactNode;
}

const Scroller: React.FC<ScrollerProps> = ({ name, condition, children }) => {
  React.useEffect(() => {
    if (condition) {
      scroller.scrollTo(name, {
        smooth: 'easeInOutCubic',
        duration: 200,
        offset: -calculateTopValue(0),
      });
    }
  }, [name, condition]);

  return <Element name={name}>{children}</Element>;
};

export default Scroller;
