import { LabelsSettings } from '@wix/restaurants-client-logic';

export type IconType = 'full-bg' | 'outline';
export type IconTypeId = 2 | 3;
export type ColorType = 'classic' | 'colors' | 'custom';
export type ColorTypeId = 0 | 1 | 2;
export type ColorRole = 'primary' | 'secondary';

export interface IconProps {
  iconType: IconType;
  colorType: ColorType;
  primaryColor?: string;
  secondaryColor?: string;
}

const DEFAULT_ICON_TYPE: IconType = 'outline';
const DEFAULT_ICON_TYPE_ID: IconTypeId = 3;
const DEFAULT_COLOR_TYPE: ColorType = 'classic';
const DEFAULT_COLOR_TYPE_ID: ColorTypeId = 0;

export const getIconTypeFromId = (id?: number): IconType => {
  let iconType: IconType;
  switch (id) {
    case 2: // support old system- use this conventions (0 & 1 not relevant for now)
      iconType = 'full-bg';
      break;
    case 3:
      iconType = 'outline';
      break;
    default:
      iconType = DEFAULT_ICON_TYPE;
      break;
  }
  return iconType;
};

export const getIdFromIconType = (type?: IconType): IconTypeId => {
  let iconTypeId: IconTypeId;
  switch (type) {
    case 'full-bg':
      iconTypeId = 2; // support old system- use this conventions (0 & 1 not relevant for now)
      break;
    case 'outline':
      iconTypeId = 3;
      break;
    default:
      iconTypeId = DEFAULT_ICON_TYPE_ID;
      break;
  }
  return iconTypeId;
};

export const getColorTypeFromId = (id?: number): ColorType => {
  let colorType: ColorType;
  switch (id) {
    case 0:
      colorType = 'classic';
      break;
    case 1:
      colorType = 'colors';
      break;
    case 2:
      colorType = 'custom';
      break;
    default:
      colorType = DEFAULT_COLOR_TYPE;
      break;
  }
  return colorType;
};

export const getIdFromColorType = (type?: ColorType): ColorTypeId => {
  let colorTypeId: ColorTypeId;
  switch (type) {
    case 'classic':
      colorTypeId = 0;
      break;
    case 'colors':
      colorTypeId = 1;
      break;
    case 'custom':
      colorTypeId = 2;
      break;
    default:
      colorTypeId = DEFAULT_COLOR_TYPE_ID;
      break;
  }
  return colorTypeId;
};

const _getIconColor = (colorType: ColorType, colorRole: ColorRole, labelsSettings?: LabelsSettings) => {
  let iconColor;
  if (colorType === 'colors') {
    iconColor = colorRole === 'primary' ? labelsSettings?.iconPrimaryColor : labelsSettings?.iconSecondaryColor;
  } else {
    iconColor =
      colorRole === 'primary' ? labelsSettings?.iconCustomPrimaryColor : labelsSettings?.iconCustomSecondaryColor;
  }
  return iconColor;
};

export const getIconProps = (labelsSettings?: LabelsSettings) => {
  const iconProps: IconProps = { iconType: DEFAULT_ICON_TYPE, colorType: DEFAULT_COLOR_TYPE };

  if (!labelsSettings) {
    return iconProps;
  }

  iconProps.iconType = getIconTypeFromId(labelsSettings?.iconTypeId);
  iconProps.colorType = getColorTypeFromId(labelsSettings?.colorTypeId);

  if (iconProps.colorType !== 'classic') {
    const primaryColor = _getIconColor(iconProps.colorType, 'primary', labelsSettings);
    const secondaryColor = _getIconColor(iconProps.colorType, 'secondary', labelsSettings);
    if (primaryColor) {
      iconProps.primaryColor = primaryColor;
    }
    if (secondaryColor) {
      iconProps.secondaryColor = secondaryColor;
    }
  }
  return iconProps;
};
