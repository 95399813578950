import { SectionNotification } from 'wix-ui-tpa';
import React from 'react';
import dataHooks from '../../data-hooks';
import ErrorIcon from 'wix-ui-icons-common/on-stage/Error';
import { BusinessNotifications, VirtualDispatchType } from '@wix/restaurants-client-logic';
import styles from './NotificationBar.scss';
import { TFunction, useTranslation } from '@wix/yoshi-flow-editor';
import { LocationsWithNotification } from '../../../../state/selectors/locationsSelector';
import { useAppSelector } from '../../../../core/hooks/redux';

function getNotificationBarText(t: TFunction, notification?: BusinessNotifications, comment?: string) {
  switch (notification) {
    case BusinessNotifications.OlOunavailable:
      return comment || t('checkout_main_onlineordering_closed_errortext');
    case BusinessNotifications.OnlyFutureOrders:
      return t('cart_issues_error_banner_restaurant_closed_future');
    case BusinessNotifications.RestaurantClosed:
      return t('cart_issues_error_banner_restaurant_closed');
    case BusinessNotifications.NotPremium:
    case BusinessNotifications.NoPaymentMethods:
    case BusinessNotifications.NoPhysicalLocation:
      return t('cart_issues_error_banner_no_olo');
    default:
      return '';
  }
}

function getNotificationBarTextMultiLocation(t: TFunction, locations: LocationsWithNotification, comment?: string) {
  if (locations.length === 0) {
    return '';
  }

  if (locations.every(({ notification }) => notification === BusinessNotifications.RestaurantClosed)) {
    return t('cart_issues_error_banner_restaurant_closed');
  } else if (locations.every(({ notification }) => notification === BusinessNotifications.OlOunavailable)) {
    return comment || t('checkout_main_onlineordering_closed_errortext');
  } else if (
    locations.every(
      ({ notification }) =>
        notification === BusinessNotifications.OlOunavailable ||
        notification === BusinessNotifications.RestaurantClosed,
    )
  ) {
    return t('cart_issues_error_banner_restaurant_closed');
  } else if (locations.every(({ notification }) => notification === BusinessNotifications.OnlyFutureOrders)) {
    return t('cart_issues_error_banner_restaurant_closed_future');
  } else if (
    locations.some(
      ({ notification }) =>
        notification === BusinessNotifications.NoPaymentMethods ||
        notification === BusinessNotifications.NoPhysicalLocation ||
        notification === BusinessNotifications.NotPremium,
    )
  ) {
    return t('cart_issues_error_banner_no_olo');
  } else {
    return '';
  }
}

export interface NotificationBarProps {
  notification?: BusinessNotifications;
  comment?: string;
  className?: string;
  hasErrorState?: boolean;
  shouldShowNotification?: (n?: BusinessNotifications) => boolean;
  locations: LocationsWithNotification;
  lockedDineInLocation?: string;
  dispatchType: VirtualDispatchType;
}

const NotificationBar: React.FC<NotificationBarProps> = ({
  notification,
  comment,
  className,
  hasErrorState = false,
  locations,
  shouldShowNotification = (n?: BusinessNotifications) => true,
  lockedDineInLocation,
  dispatchType,
}) => {
  const { t } = useTranslation();
  const isMultiLocation = useAppSelector((state) => state.session.isMultiLocation);
  const text = shouldShowNotification(notification)
    ? isMultiLocation && !(dispatchType === 'dine-in' && lockedDineInLocation)
      ? getNotificationBarTextMultiLocation(t, locations, comment)
      : getNotificationBarText(t, notification, comment)
    : '';
  const notificationType =
    hasErrorState &&
    (notification === BusinessNotifications.NotPremium ||
      notification === BusinessNotifications.NoPaymentMethods ||
      notification === BusinessNotifications.NoPhysicalLocation)
      ? 'error'
      : 'default';
  return text ? (
    <div className={`${className} ${styles.outerWrapper}`} data-hook={dataHooks.notificationBar}>
      <SectionNotification type={notificationType} className={styles.wrapper}>
        {notificationType === 'error' && <SectionNotification.Icon data-hook="error-icon" icon={<ErrorIcon />} />}
        <SectionNotification.Text>{text}</SectionNotification.Text>
      </SectionNotification>
    </div>
  ) : null;
};

NotificationBar.displayName = 'NotificationBar';

export default NotificationBar;
