import React from 'react';
import { scroller } from 'react-scroll';
import { useBi } from '@wix/yoshi-flow-editor';
import { DisplayableMenu } from '@wix/restaurants-client-logic';
import { calculateTopValue } from '../Scroller/scrollingUtils';
import dataHooks from '../../data-hooks';
import styles from './MenuPicker.scss';
import FloatingDropdown from '../FloatingDropdown';
import { navigateToMenu } from '@wix/bi-logger-olo-client/v2';

export interface MenuPickerProps {
  menus: DisplayableMenu;
}

const getScrollOptions = () => {
  return {
    smooth: 'easeInOutCubic',
    duration: 200,
    offset: -(20 + calculateTopValue(0)),
  };
};

const MenuPicker: React.FC<MenuPickerProps> = ({ menus }) => {
  const [selectedMenuId, setMenuId] = React.useState(menus[0]?.id);
  const biLogger = useBi();

  return (
    <div className={styles.wrapper}>
      <FloatingDropdown
        label=""
        data-hook={dataHooks.menuPickerDropdown}
        options={menus.map((e) => ({ id: e.id, value: e.displayableTitle || '', isSelectable: true }))}
        value={selectedMenuId}
        onChange={(selectedOption) => {
          if (selectedOption.id) {
            biLogger.report(navigateToMenu({ menuId: selectedOption.id, menuName: selectedOption.value }));
            scroller.scrollTo(selectedOption.id, getScrollOptions());
            setMenuId(selectedOption.id);
          }
        }}
        className={styles.dropdown}
        displayBlock
      />
    </div>
  );
};

export default MenuPicker;
