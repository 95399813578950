import React from 'react';
import dataHooks from '../../data-hooks';
import Text from '../../core-components/Text';
import TextField from '../TextField';
import { CurbsideInfo } from '@wix/restaurants-client-logic';
import ToggleSwitch from '../ToggleSwitch';
import styles from './CurbsidePickup.scss';
import { useTranslation } from '@wix/yoshi-flow-editor';

export interface CurbsidePickupProps {
  curbsideOutfitInfo?: string;
  setCurbsideOutfitInfo: (outfitInfo: string) => void;
  curbsideInfo?: CurbsideInfo;
  isCurbsideOn?: boolean;
  onCurbsideToggle: (state: boolean) => void;
  outfitInfoError: boolean;
}

const CurbsidePickup = ({
  isCurbsideOn,
  onCurbsideToggle,
  curbsideInfo,
  curbsideOutfitInfo,
  setCurbsideOutfitInfo,
  outfitInfoError,
}: CurbsidePickupProps) => {
  const { t } = useTranslation();
  const instructionsText = curbsideInfo ? curbsideInfo?.instructions : '';
  const additionalInfoLabel = curbsideInfo?.additionalInformation;

  return (
    <div className={styles.wrapper}>
      <div className={styles.toggleWrapper}>
        <ToggleSwitch
          data-hook={dataHooks.dispatchSettingCurbsideToggle}
          checked={isCurbsideOn}
          onChange={() => {
            onCurbsideToggle(!isCurbsideOn);
          }}
        />
        <Text typography="p2-m" className={styles.toggleText}>
          {t('mobile.delivery_modal_PickupOption_Curbside_Toggle')}
        </Text>
      </div>
      {isCurbsideOn && (
        <div className={styles.instructionsWrapper}>
          <Text typography="p2-m" data-hook={dataHooks.dispatchSettingCurbsideInstructions}>
            {instructionsText}
          </Text>
          {curbsideInfo?.additionalInformationRequired && (
            <div
              className={styles.outfitInfoLabelWrapper}
              data-hook={dataHooks.dispatchSettingCurbsideOutfitInfoWrapper}
            >
              <TextField
                label={`${additionalInfoLabel} *`}
                data-hook={dataHooks.dispatchSettingCurbsideOutfitInfo}
                value={curbsideOutfitInfo || ''}
                onChange={(e) => setCurbsideOutfitInfo(e.target.value)}
                error={outfitInfoError}
                errorMessage={t('delivery_modal_PickupOption_Curbside_FieldLabel_ErrorMessage')}
                className={styles.outfitInfoLabel}
                maxLength={50}
                showCharCount={true}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CurbsidePickup;
