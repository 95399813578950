export function getInterpolateFunction(inputRange: [number, number], outputRange: [number, number]) {
  return (n: number) => {
    const result =
      ((n - inputRange[0]) * (outputRange[1] - outputRange[0])) / (inputRange[1] - inputRange[0]) + outputRange[0];

    const min = Math.min(outputRange[0], outputRange[1]);
    const max = Math.max(outputRange[0], outputRange[1]);
    return Math.min(Math.max(result, min), max);
  };
}
