import React from 'react';
import { AddressOutput, Handler } from 'wix-ui-core/dist/src/clients/GoogleMaps/types';
import { getAtlasClientConstructor, getAddressFromGoogleResult } from '../../../../core/AtlasClient/AtlasClient';
import { EMPTY_ADDRESS } from '../../../../core/constants';
import { AddressInput as CoreAddressInput } from 'wix-ui-core';
import { classes } from './AddressInput.st.css';
import { IconButton } from 'wix-ui-tpa';
import CheckXSmall from 'wix-ui-icons-common/on-stage/system/CheckXSmall';
import dataHooks from '../../data-hooks';
import ClearXSmall from 'wix-ui-icons-common/on-stage/system/ClearXSmall';
import { Address } from '@wix/restaurants-client-logic';
import classNames from 'classnames';
import { AddressLocation } from '@wix/ambassador-wix-atlas-service-web/src/types';
import { useTranslation, useFedopsLogger, useBi } from '@wix/yoshi-flow-editor';
import { ErrorMessageWrapper } from './ErrorMessageWrapper/ErrorMessageWrapper';

export interface AddressInputProps {
  value: string;
  onSelect: (address: Address) => void;
  'data-hook'?: string;
  errorText?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  signedInstance: string;
  isMobile: boolean;
  onBlur?: (address?: Address) => void;
  isSuccess?: boolean;
  locale?: string;
  origin?: AddressLocation;
  appendToElement?: Element;
  isViewer: boolean;
}

const AddressInput: React.FC<AddressInputProps> = ({
  value,
  onSelect,
  errorText,
  onChange,
  placeholder,
  signedInstance,
  isMobile,
  onBlur = () => {},
  isSuccess,
  locale,
  origin,
  appendToElement,
  isViewer,
  ...rest
}) => {
  const fedopsLogger = useFedopsLogger();
  const biLogger = useBi();
  const { t } = useTranslation();

  const ref = React.createRef<CoreAddressInput>();
  let selectedAddress: Address | undefined;

  const getSuffix = () => {
    return (
      <>
        {value && (
          <div className={classes.clearButtonWrapper}>
            <IconButton
              className={classes.clearButton}
              data-hook={dataHooks.addressInformationInputClear}
              aria-label="Clear"
              onClick={() => {
                onChange('');
                onSelect(EMPTY_ADDRESS);

                if (ref.current) {
                  ref.current.clear();
                }
              }}
              icon={<ClearXSmall />}
            />
          </div>
        )}
        {isSuccess && (
          <div data-hook={dataHooks.addressInformationInputSuccessIcon} className={classes.iconWrapper}>
            <CheckXSmall />
          </div>
        )}
      </>
    );
  };

  const preOnSelect = (raw: AddressOutput) => {
    if (ref.current?.props.value) {
      ref.current.close();
      ref.current.blur();

      const address = getAddressFromGoogleResult(raw);
      onSelect(address);
      selectedAddress = address;
    }
  };

  const className = classNames({
    [classes.root]: true,
    [classes.regular]: !errorText && !isSuccess,
    [classes.error]: errorText,
    [classes.success]: isSuccess,
  });
  const inputClassName = classNames({
    [classes.inputRegular]: true,
  });

  const AtlasClient = getAtlasClientConstructor({ signedInstance, locale, origin, fedopsLogger, isViewer, biLogger });

  return (
    <div data-hook={rest['data-hook'] || 'address-input'}>
      <ErrorMessageWrapper
        visible={!!errorText}
        message={errorText || ''}
        messageId="bad-address-input"
        dataHook={dataHooks.addressInformationInputErrorText}
      >
        <CoreAddressInput
          suffix={getSuffix()}
          key={!appendToElement ? 'addressInput_NotFoundModalYet' : undefined}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setImmediate(() => {
                if (ref.current) {
                  ref.current.blur();
                }
              });
            }
          }}
          // id="address-input"
          className={className}
          handler={Handler.places}
          data-hook={dataHooks.addressInformationCoreInput}
          clientId=""
          onChange={(e) => {
            selectedAddress = undefined!;
            onChange(e.target.value);
          }}
          emptyStateMessage={t('checkout_main_delivery_address_noresults')}
          onSelect={(raw) => preOnSelect(raw)}
          Client={AtlasClient as any}
          // @ts-expect-error
          converterType="simple"
          value={value}
          fixed
          placeholder={placeholder}
          ref={ref}
          throttleInterval={400}
          forceSelect
          optionStyle={isMobile ? { fontSize: '14px' } : {}}
          onBlur={() => onBlur(selectedAddress)}
          lang={locale?.split('_')[0]}
          locale={locale}
          appendTo={appendToElement}
          dynamicWidth
          optionsContainerZIndex={1020}
          inputClassName={inputClassName}
        />
      </ErrorMessageWrapper>
    </div>
  );
};

AddressInput.displayName = 'AddressInput';
export default AddressInput;
