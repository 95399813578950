import React from 'react';
import { Dropdown as TpaDropdown, DropdownProps as TpaDropdownProps } from 'wix-ui-tpa';
import { st, classes } from './Dropdown.st.css';

export interface DropdownProps extends TpaDropdownProps {}

const Dropdown: React.FC<DropdownProps> = (props) => {
  return <TpaDropdown {...props} className={st(classes.root, {}, props.className)} newErrorMessage />;
};

Dropdown.displayName = 'Dropdown';

export default Dropdown;
