import React from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import Text from '../../core-components/Text';
import styles from './ModalLayoutBasic.scss';
import dataHooks from '../../data-hooks';
import Close from '../../../../assets/icons/close.svg';
import classNames from 'classnames';

export interface ModalLayoutBasicProps {
  children?: React.ReactNode;
  header: string | React.ReactNode;
  footer?: React.ReactNode;
  onCloseClick: () => void;
  'data-hook'?: string;
  className?: string;
}

function unlockScroll() {
  return new Promise((resolve) => {
    clearAllBodyScrollLocks();
    setTimeout(resolve, 16);
  });
}

const ModalLayoutBasic: React.FC<ModalLayoutBasicProps> = ({
  header,
  children,
  footer,
  onCloseClick,
  className,
  ...rest
}) => {
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    unlockScroll().then(() => {
      if (scrollContainerRef.current) {
        disableBodyScroll(scrollContainerRef.current, {
          // allow scroll on dropdowns
          allowTouchMove: (el) => el.className.indexOf('options') > -1 || el.className.indexOf('dropdown') > -1,
        });
      }
    });

    return () => {
      unlockScroll();
    };
  }, []);

  return (
    <div className={classNames([styles.wrapper, className])} data-hook={rest['data-hook']}>
      <button
        data-hook={dataHooks.modalCloseButton}
        onClick={() => onCloseClick()}
        className={styles.closeIcon}
        aria-label="Close"
        autoFocus
      >
        <Close />
      </button>
      <Text typography="header-l" tagName="h2" className={styles.header}>
        {header}
      </Text>
      <div className={styles.content} ref={scrollContainerRef}>
        {children}
      </div>
      {footer}
    </div>
  );
};

ModalLayoutBasic.displayName = 'ModalLayoutBasic';

export default ModalLayoutBasic;
