import { useState, useEffect } from 'react';

let CachedPhoneUtils: any;

export default () => {
  const [PhoneUtils, setPhoneUtils] = useState(CachedPhoneUtils);

  useEffect(() => {
    let isMounted = true;

    if (!PhoneUtils) {
      import('@wix/restaurants-client-logic').then((lib) => {
        if (isMounted) {
          CachedPhoneUtils = lib.PhoneUtils;
          setPhoneUtils(CachedPhoneUtils);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [PhoneUtils]);

  return PhoneUtils;
};
