import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CartIcon from '../CartIcon';
import styles from './CartButton.scss';
import Text from '../../core-components/Text';
import { ButtonPriority as PRIORITY } from 'wix-ui-tpa';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import Button from '../Button';
import dataHooks from '../../data-hooks';
import useMinOrderPriceDetails from '../../../../core/hooks/useMinOrderPriceDetails';
import { liveSiteMinimumOrderError, viewCart } from '@wix/bi-logger-olo-client/v2';

function noop() {}
const isIphone = typeof navigator !== 'undefined' && navigator.platform === 'iPhone';

export interface CartButtonProps {
  itemCount: number;
  fullWidth?: boolean;
  dishModalOpen?: boolean;
  dispatchType: string;
  totalOrderPrice: number;
  pointsToBeEarned: number;
  pointsBalance: number;
  redeemPoints: number;
  redeemCents: number;
  loyaltyRewardId: string;
}

const CartButton: React.FC<CartButtonProps> = ({
  itemCount,
  fullWidth,
  dishModalOpen,
  dispatchType,
  totalOrderPrice,
  pointsToBeEarned,
  pointsBalance,
  redeemPoints,
  redeemCents,
  loyaltyRewardId,
}) => {
  const { t } = useTranslation();
  const [pointerEvents, setPointerEvents] = React.useState(!isIphone);
  const { minOrderPrice, isMinOrderPriceMet } = useMinOrderPriceDetails();
  const history = useHistory();
  const biLogger = useBi();

  const hook = dataHooks.openCartButton;

  useEffect(() => {
    if (isIphone) {
      if (dishModalOpen) {
        setPointerEvents(false);
      } else {
        setTimeout(() => {
          setPointerEvents(true);
        }, 250);
      }
    }
  }, [dishModalOpen]);

  const onViewCartBtnClick = () => {
    if (!isMinOrderPriceMet) {
      biLogger.report(
        liveSiteMinimumOrderError({
          price: totalOrderPrice,
          minimumOrder: minOrderPrice,
          pageName: 'view cart',
          dispatchType,
        }),
      );
    }

    biLogger.report(
      viewCart({
        totalItemsCount: itemCount,
        dispatchType,
        loyaltyEstimatedEarend: pointsToBeEarned,
        totalLoyaltyPoints: pointsBalance,
        loyaltyPoints: redeemPoints,
        loyaltyPrice: redeemCents,
        rewardId: loyaltyRewardId,
      }),
    );
    history.push('/cart');
  };

  const button = (
    <div className={styles.iconWithCount}>
      <CartIcon className={styles.cartIcon} />
      <Text typography="p2-s-colorless" className={styles.textEmpty} data-hook={dataHooks.cartButtonItemCount}>
        {itemCount}
      </Text>
    </div>
  );

  const empty = (
    <button
      aria-label={t('cart_viewcart_cta')}
      className={styles.wrapperEmpty}
      data-hook={hook}
      onClick={() => history.push('/cart')}
    >
      {button}
    </button>
  );

  const full = (
    <Button
      upgrade
      priority={PRIORITY.primary}
      suffixIcon={button}
      className={`${styles.button} ${!pointerEvents ? styles.noPointer : ''}`}
      data-hook={hook}
      onClick={onViewCartBtnClick}
      fullWidth={fullWidth}
      onBlur={noop}
      onFocus={noop}
    >
      <Text typography="p2-m-colorless">{t('online_ordering_cta_view_cart')}</Text>
    </Button>
  );

  return itemCount === 0 ? empty : full;
};

CartButton.displayName = 'CartButton';

export default CartButton;
