import React from 'react';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { SetContactlessDeliveryPayload } from '../../../../state/checkout/checkout.actions.types';
import ToggleSwitch from '../ToggleSwitch';
import dataHooks from '../../data-hooks';
import Text from '../../core-components/Text';
import styles from './ContactlessDeliveryToggle.scss';

interface ContactlessDeliveryToggleProps {
  className?: string;
  isContactlessDeliveryChecked?: boolean;
  setContactlessDelivery: (payload: SetContactlessDeliveryPayload) => void;
}
export const ContactlessDeliveryToggle: React.FC<ContactlessDeliveryToggleProps> = ({
  className,
  isContactlessDeliveryChecked,
  setContactlessDelivery,
}) => {
  const { experiments } = useExperiments();
  const { t } = useTranslation();
  return (
    <label className={`${styles.contactless} ${className}`}>
      {
        <div data-hook={dataHooks.checkoutReviewContactlessToggleLabel}>
          <Text typography="p2-m" className={styles.contactlessLabelNew}>
            {t('checkout_main_orderreview_nocontact_toggle')}
          </Text>
        </div>
      }
      <div className={styles.contactlessToggle}>
        <ToggleSwitch
          data-hook={dataHooks.checkoutReviewContactlessToggle}
          checked={isContactlessDeliveryChecked}
          onChange={() => {
            setContactlessDelivery({ isContactless: !isContactlessDeliveryChecked });
          }}
        />
      </div>
    </label>
  );
};

ContactlessDeliveryToggle.displayName = 'ContactlessDeliveryToggle';

export default ContactlessDeliveryToggle;
