import React from 'react';
import { NavigationHeaderEntity } from '../NavigationHeader/NavigationHeader';
import { Link } from 'react-scroll';
import styles from './PseudoLinks.scss';

export interface PseudoLinksProps {
  menus?: NavigationHeaderEntity[];
  sections: NavigationHeaderEntity[];
  onChange: (type: 'menu' | 'section', id: string) => void;
  offset?: number;
}

const PseudoLinks: React.FC<PseudoLinksProps> = ({ menus, sections, onChange, offset = -50 }) => {
  return (
    <div className={styles.wrapper}>
      {menus?.map((menu) => {
        return (
          <Link
            key={menu.id}
            to={menu.id}
            spy
            activeClass={styles.active}
            onSetActive={(id) => id && onChange('menu', id)}
            offset={offset}
          >
            {menu.title}
          </Link>
        );
      })}

      {sections.map((section) => {
        return (
          <Link
            key={section.id}
            to={section.id}
            spy
            activeClass={styles.active}
            onSetActive={(id) => id && onChange('section', id)}
            offset={offset}
          >
            {section.title}
          </Link>
        );
      })}
    </div>
  );
};

PseudoLinks.displayName = 'PseudoLinks';

export default PseudoLinks;
