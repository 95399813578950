import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import CartButton from './CartButton';
import { Modals } from '../../../../core/constants';
import { cartSummarySelector } from '../../../../state/selectors/cartSummarySelector';
import { loyaltyProgramSelector } from '../../../../state/selectors/loyaltyProgramSelector';
import { calcEarnedPoints } from '@wix/restaurants-client-logic';

function mapStateToProps(state: AppState, ownProps: any) {
  const { itemCount, totalOrderPrice, redeemCents, redeemPoints, priceComponents } = cartSummarySelector(state);
  const { loyaltyRewardId, pointsBalance } = loyaltyProgramSelector(state);
  const pointsToBeEarned = calcEarnedPoints({
    rules: state.session.loyaltyEarningRules || [],
    moneyAmountInCents: priceComponents.subtotal,
  });

  return {
    ...ownProps,
    dishModalOpen: !!state.session.modals.find((modal) => modal.modal === Modals.DISH_MODAL),
    itemCount,
    dispatchType: state.checkout.dispatch.type,
    totalOrderPrice,
    pointsToBeEarned,
    pointsBalance,
    redeemPoints,
    redeemCents,
    loyaltyRewardId,
  };
}

export default connect(mapStateToProps)(CartButton);
