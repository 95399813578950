import React from 'react';

interface WrappedComponentProps {
  isMobile?: boolean;
}

export interface InjectedStyleProps {
  headerStyle?: any;
  footerStyle?: any;
}

export interface IosResizeState {
  isIPhoneKeyboardOpen: boolean;
  headerTopOffset: string;
}

interface VisualViewport extends EventTarget {
  readonly offsetTop: number;
}

export function withHandleIosResize<P extends InjectedStyleProps>(WrappedComponent: React.ComponentType<P>) {
  return class extends React.Component<Omit<P, keyof InjectedStyleProps> & WrappedComponentProps, IosResizeState> {
    constructor(props: P) {
      super(props);
      this.state = { isIPhoneKeyboardOpen: false, headerTopOffset: '0' };
    }

    componentDidMount() {
      if (this.props.isMobile) {
        try {
          const visualViewport = (window as any).visualViewport;
          if (visualViewport) {
            visualViewport.addEventListener('resize', (e: UIEvent) => this.handleResize(e));
          }
        } catch (error) {
          console.error(error);
        }
      }
    }

    componentWillUnmount() {
      if (this.props.isMobile) {
        try {
          const visualViewport = (window as any).visualViewport;
          if (visualViewport) {
            visualViewport.removeEventListener('resize', (e: UIEvent) => this.handleResize(e));
          }
        } catch (error) {
          console.error(error);
        }
      }
    }

    handleResize(e: UIEvent) {
      const viewport = e.target as VisualViewport;
      const viewportTopOffset = viewport.offsetTop || 0;
      const isIPhoneKeyboardOpen = viewportTopOffset > 1; // there is an offset & it's significate
      this.setState({ isIPhoneKeyboardOpen, headerTopOffset: `${viewportTopOffset}px` });
    }

    render() {
      const { isIPhoneKeyboardOpen, headerTopOffset } = this.state;
      let headerStyle = {};
      const footerStyle = { display: 'block' };

      if (isIPhoneKeyboardOpen) {
        headerStyle = { top: headerTopOffset, position: 'relative', zIndex: 1 };
        footerStyle.display = 'none';
      }
      return <WrappedComponent {...(this.props as P)} headerStyle={headerStyle} footerStyle={footerStyle} />;
    }
  };
}
