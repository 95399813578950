import AddressInformation from './AddressInformation';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  setCurbside,
  setCurbsideOutfitInfo,
  setDeliveryAddress,
  setDeliveryAddressFromForm,
  setDineInTable,
  setDispatchTime,
  setDispatchTimeCache,
  setDispatchType,
  loginMember,
  setDeliveryProviderEstimate,
  setIsFetchingEstimateFromTPA,
  removeDeliveryProviderFromState,
  setCalculatedFees,
  setIsCalculatingServiceFees,
} from '../../../../state/checkout/checkout.actions';
import { getCurbsideInfo, getFormattedAddressWithComment } from '@wix/restaurants-client-logic';
import { AppState } from '../../../../state/createStore';
import { EMPTY_ADDRESS, Modals } from '../../../../core/constants';
import { cartSummarySelector } from '../../../../state/selectors/cartSummarySelector';
import {
  multiLocationSupportedDispatchTypesSelector,
  supportedDispatchTypesSelector,
  supportedDispatchTypesSetSelectorV2,
} from '../../../../state/selectors/supportedDispatchTypesSelector';
import { loyaltyProgramSelector } from '../../../../state/selectors/loyaltyProgramSelector';
import {
  setAddressInputError,
  setErrorVisibility,
  setFieldError,
  toggleAllErrors,
  setDeliveryAddressField,
  validateForm,
  setTpaEstimateInlineError,
  setAddressInputValue,
} from '../../../../state/addressInformationForm/addressForm.actions';
import { deliveryAreaSelectorByAddressForm } from '../../../../state/selectors/deliveryAreaSelector';
import {
  openModal,
  saveAddressToServer,
  saveStateToSessionStorage,
  getCurrentPacingLevel,
} from '../../../../state/session/session.actions';
import { DeliveryDispatch, PickupDispatch } from '@wix/restaurants-client-logic/dist/types/types/Dispatch';
import { getDineInInfo, getVirtualDispatchTypeFromDispatch } from '../../../../core/logic/dispatchLogic';
import { selectOpenLocations } from '../../../../state/selectors/locationsSelector';
import { selectOrderPacingLevel } from '../../../../state/selectors/orderPacingSelector';
import { PaymentMethod } from '@wix/cashier-payments-widget';
import { updateCalculatedFeesDecorator } from '../../../../core/calcServiceFees';
import { getCalculatedFeesParamsSelector } from '../../../../state/selectors/calculatedFeesSelector';

function mapStateToProps(state: AppState, ownProps: any) {
  const { priceComponents, displayableOrderItems } = cartSummarySelector(state);
  const { restaurant } = state.session;
  const idealDeliveryArea = deliveryAreaSelectorByAddressForm(state);
  const curbsideInfo = getCurbsideInfo(state.session.restaurant.deliveryInfos);
  const isMultiLocation = state.session.isMultiLocation;
  const { customPointsName, pointsBalance } = loyaltyProgramSelector(state);
  const calculatedFeesParams = getCalculatedFeesParamsSelector(state);

  return {
    ...ownProps,
    dispatchType: getVirtualDispatchTypeFromDispatch(state.checkout.dispatch),
    address: (state.checkout.dispatch as DeliveryDispatch)?.address || EMPTY_ADDRESS,
    restaurant,
    totalOrderPrice: priceComponents.total,
    subtotal: priceComponents.subtotal,
    dispatchTime: state.checkout.dispatch.time,
    addressInputError: state.addressForm.addressInputError,
    fieldsErrors: state.addressForm.fieldErrors,
    supportedDispatchTypes: isMultiLocation
      ? multiLocationSupportedDispatchTypesSelector(state)
      : supportedDispatchTypesSelector(state),
    supportedDispatchTypesV2: supportedDispatchTypesSetSelectorV2(state),
    formattedAddressWithComment: getFormattedAddressWithComment(restaurant.address),
    isMobile: state.platformParams.isMobile,
    selectedAddressOption: state.addressForm.selectedAddressOption,
    idealDeliveryArea: idealDeliveryArea?.dispatchInfo,
    errorOrderItem: displayableOrderItems.find((orderItem) => orderItem.errors.length || orderItem.isDishDeleted),
    isCartEmpty: displayableOrderItems.length === 0,
    timezone: state.session.restaurant.timezone,
    errorsVisibility: state.addressForm.errorsVisibility,
    deliveryInfos: restaurant.deliveryInfos,
    locale: state.session.restaurant.locale,
    savedAddresses: state.session.savedAddresses,
    isLoadingAddressesFromServer: state.session.isLoadingAddressesFromServer,
    initialTableNumber: (state.checkout.dispatch as PickupDispatch).contactlessDineIn?.labelValue,
    initialCurbside: state.checkout.dispatch.type === 'takeout' ? state.checkout.dispatch.isCurbside : undefined,
    initialCurbsideOutfitInfo:
      state.checkout.dispatch.type === 'takeout' ? state.checkout.dispatch.curbsideAdditionalInformation : undefined,
    curbsideInfo,
    locations: selectOpenLocations(state),
    isAddressSelectionModalOpen: Boolean(state.session.modals.find((m) => m.modal === Modals.ADDRESS_SELECTION)),
    isUserLoggedIn: state.session.isUserLoggedIn,
    dineInInfo: getDineInInfo(state.session.restaurant),
    isMAInstalled: state.session.isMAInstalled,
    isMultiLocation,
    currentLocationId: state.session.restaurant.currentLocationId,
    isLocationPicked: state.session.isLocationPicked,
    lockedDineInLocation: state.session.locations?.find(
      (location) => location.currentLocationId === state.session.lockedDineInLocation,
    ),
    loggedInUserEmail: state.session.loggedInUserEmail,
    customPointsName,
    pointsBalance,
    modalOrigin:
      ownProps.viewedFrom === 'multi-location-modal'
        ? state.session.modals.find((modal) => modal.modal === Modals.ADDRESS_INFORMATION_MODAL)
        : undefined,
    earningRules: state.session.loyaltyEarningRules || [],
    signedInstance: state.platformParams.signedInstance,
    tpaEstimateInlineError: state.addressForm.tpaEstimateInlineError,
    isFetchingEstimateFromTPA: state.checkout.isFetchingEstimateFromTPA,
    isCalculatingServiceFees: state.checkout.isCalculatingServiceFees,
    hasOnlinePayment: state.session.paymentMethods.find(
      (payment) =>
        payment.paymentMethod !== PaymentMethod.Cash &&
        payment.paymentMethod !== PaymentMethod.InPerson &&
        payment.paymentMethod !== PaymentMethod.Offline,
    ),
    orderPacingLevel: selectOrderPacingLevel(state),
    isASAPDisabled: state.session.restaurant.orders.asap.disabled,
    updateCalculatedFees: updateCalculatedFeesDecorator(calculatedFeesParams),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setDispatchType,
      setDeliveryAddress,
      setDispatchTime,
      setDispatchTimeCache,
      toggleAllErrors,
      setDeliveryAddressFromForm,
      setAddressInputError,
      setFieldError,
      saveStateToSessionStorage,
      setErrorVisibility,
      openModal,
      saveAddressToServer,
      setCurbside,
      setCurbsideOutfitInfo,
      setDineInTable,
      setDeliveryAddressField,
      loginMember,
      validateForm,
      setDeliveryProviderEstimate,
      setIsFetchingEstimateFromTPA,
      removeDeliveryProviderFromState,
      setTpaEstimateInlineError,
      setAddressInputValue,
      getCurrentPacingLevel,
      setCalculatedFees,
      setIsCalculatingServiceFees,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AddressInformation);
