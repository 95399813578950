import React from 'react';
import styles from './DiscountIconV2.scss';
import classNames from 'classnames';

const DiscountIconV2: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      version="1.1"
      className={classNames([styles.wrapper, className])}
    >
      <g stroke="none" strokeWidth="1" fill="none">
        <path
          className={styles.path}
          d="M14.5,3.998 C20.29,3.998 25,8.708 25,14.498 C25,20.288 20.29,24.998 14.5,24.998 C8.71,24.998 4,20.288 4,14.498 C4,8.708 8.71,3.998 14.5,3.998 Z M14.5,4.998 C9.262,4.998 5,9.26 5,14.498 C5,19.736 9.262,23.998 14.5,23.998 C19.738,23.998 24,19.736 24,14.498 C24,9.26 19.738,4.998 14.5,4.998 Z M17.75,15.5 C18.993,15.5 20,16.508 20,17.75 C20,18.992 18.993,20 17.75,20 C16.507,20 15.5,18.992 15.5,17.75 C15.5,16.508 16.507,15.5 17.75,15.5 Z M18.6465,9.6465 L19.3535,10.3535 L10.3535,19.3535 L9.6465,18.6465 L18.6465,9.6465 Z M17.75,16.5 C17.061,16.5 16.5,17.061 16.5,17.75 C16.5,18.439 17.061,19 17.75,19 C18.439,19 19,18.439 19,17.75 C19,17.061 18.439,16.5 17.75,16.5 Z M11.25,9 C12.493,9 13.5,10.008 13.5,11.25 C13.5,12.492 12.493,13.5 11.25,13.5 C10.007,13.5 9,12.492 9,11.25 C9,10.008 10.007,9 11.25,9 Z M11.25,10 C10.561,10 10,10.561 10,11.25 C10,11.939 10.561,12.5 11.25,12.5 C11.939,12.5 12.5,11.939 12.5,11.25 C12.5,10.561 11.939,10 11.25,10 Z"
        />
      </g>
    </svg>
  );
};

DiscountIconV2.displayName = 'DiscountIconV2';

export default DiscountIconV2;
