import React from 'react';
import { extractLocalizedString, LocalizedString } from '@wix/restaurants-client-logic';
import Text from '../../core-components/Text';
import dataHooks from '../../data-hooks';

import styles from './LocationHeader.scss';
import { useTranslation } from '@wix/yoshi-flow-editor';

interface Props {
  name?: LocalizedString;
  locale: string;
  address: string;
  minOrderPrice?: string;
}
const LocationHeader: React.FC<Props> = ({ address, name, locale, minOrderPrice }) => {
  const { t } = useTranslation();

  return (
    <div data-hook={dataHooks.locationHeader} className={styles.wrapper}>
      {name && (
        <Text tagName="h2" typography="header-l">
          {extractLocalizedString(name, locale)}
        </Text>
      )}
      <Text className={styles.subHeader} tagName="p" typography="p2-s">
        {address}
      </Text>
      {minOrderPrice && (
        <Text className={styles.minPrice} typography="p2-s-secondary">
          {t('Order_Online_MinimumOrder_Amount_Label', { amount: minOrderPrice })}
        </Text>
      )}
    </div>
  );
};
export default LocationHeader;
