import React, { useEffect, useRef } from 'react';
import { scroller } from 'react-scroll';
import { ButtonPriority as PRIORITY } from 'wix-ui-tpa';
import {
  getDisplayablePrice,
  Payment,
  Tip,
  YearMonth,
  TipPresets,
  currencySymbol as getCurrencySymbol,
  Policy,
} from '@wix/restaurants-client-logic';
import { useBi, useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { CheckoutStep } from '../../../../core/types/Checkout';
import dataHooks from '../../data-hooks';
import { calculateTopValue } from '../Scroller/scrollingUtils';
import CheckoutFlowStepTitle from '../CheckoutFlowStepTitle';
import Cashier from '../Cashier';
import Scroller from '../Scroller';
import Text from '../../core-components/Text';
import {
  SetCashierPaymentPayload,
  SetPendingCashierPaymentPayload,
  SetTipPayload,
} from '../../../../state/checkout/checkout.actions.types';
import { ApiUnavailable, NotValidError, validateAndInitializePayment } from '../../../../core/logic/cashierLogic';
import { InitializePaymentResult } from '@wix/cashier-payments-widget';
import TipPicker from '../TipPicker/TipPicker';
import CheckoutPaymentsSummary from '../CheckoutPaymentsSummary';
import Button from '../Button';
import styles from './CheckoutPayments.scss';
import _ from 'lodash';
import { defaultTips, getTipValue } from '../TipPicker/TipPickerUtils';
import { paymentsInformationContinue, paymentsInformationContinueValidationError } from '@wix/bi-logger-olo-client/v2';
import CheckoutExpress from '../CheckoutExpress';
import { PaymentAuthorizedArgs } from '@wix/cashier-express-checkout-widget/dist/src/types/ExternalContract';
import { waitUntil } from '../../../../core/helpers/promiseUtils';

const APPLE_PAY_PAYMENT_METHOD_ID = 'applePay';
// will be implemented on cashier side
const APPLE_PAY_PAYMENT_METHOD_TITLE = 'Apple Pay';
function convertToPayload({ detailsId, cardPublicData }: InitializePaymentResult) {
  const result: SetCashierPaymentPayload = { paymentDetailsId: detailsId };

  if (cardPublicData) {
    result.creditCard = {
      network: cardPublicData.type,
      expiration: cardPublicData.expiration as YearMonth,
      lastDigits: cardPublicData.lastFourDigits || '',
    };
  }

  return result;
}

function convertCashierErrorToString(errorResult: NotValidError | ApiUnavailable) {
  let cashierStringError = errorResult.error;
  if ('invalidFields' in errorResult) {
    cashierStringError += `: ${errorResult.invalidFields.join(',')}`;
  }
  return cashierStringError;
}

export interface CheckoutPaymentsProps {
  done?: boolean;
  collapsed?: boolean;
  index?: string;
  shouldDisplayTip: boolean;
  payments: Payment[];
  step: CheckoutStep;
  tip?: Tip;
  isLoading?: boolean;
  currency: string;
  locale: string;
  totalOrderPrice: number;
  tipCharge: number;
  tipPresets: TipPresets;
  paymentMethod: string | undefined;
  onEdit: () => void;
  onSubmit: (skipReviewStep?: boolean) => void;
  setTip: (payload: SetTipPayload) => void;
  setCashierPayment: (payload: SetCashierPaymentPayload) => void;
  setPendingCashierPayment: (payload: SetPendingCashierPaymentPayload) => void;
  setCashierPaymentDone: () => void;
  describedby?: string;
  siteIsTemplate?: boolean;
  isMobile: boolean;
  orderPolicy?: Policy;
  orderResponse: any;
  isSubmitOrderFailed?: boolean;
}

const CheckoutPayments: React.FC<CheckoutPaymentsProps> = ({
  done,
  collapsed,
  index = '0',
  shouldDisplayTip,
  payments,
  step,
  tip,
  isLoading,
  currency,
  locale,
  totalOrderPrice,
  tipCharge,
  paymentMethod,
  tipPresets,
  onEdit,
  onSubmit,
  setTip,
  setCashierPayment,
  setPendingCashierPayment,
  setCashierPaymentDone,
  describedby,
  siteIsTemplate,
  isMobile,
  orderPolicy,
  orderResponse,
  isSubmitOrderFailed,
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [keepCashierOpen, setKeepCashierOpen] = React.useState(false);
  const [applePayAuthorized, setApplePayAuthorized] = React.useState(false);
  const submitOrderFinished = useRef<{ finished: boolean; withError?: boolean }>({ finished: false, withError: false });

  const biLogger = useBi();
  const { experiments } = useExperiments();
  // if tip presets haven't been configured yet, they will be the default values
  tipPresets = _.isEmpty(tipPresets) ? defaultTips : tipPresets;
  // for tip bi events
  const selectedTipPresets =
    tipPresets.tipOption === 'percentageValues' ? tipPresets.percentageValues : tipPresets.currencyValues;
  const defaultTipValue = _.find(selectedTipPresets, (tipPreset) => tipPreset.isDefault === true)?.value;
  const defaultTipValueSelected =
    tip &&
    !tip.isCustom &&
    (tip.amount === defaultTipValue ||
      tip.amount / 100 === defaultTipValue ||
      (tip.amount === 0 && defaultTipValue === 'None'));
  const selectedTipValue = tip?.isCustom
    ? 'custom'
    : _.find(selectedTipPresets, (tipPreset) => {
        const tipValueToCompare = tip && (tipPresets.tipOption === 'percentageValues' ? tip.amount : tip.amount / 100);
        return tipPreset.value === tipValueToCompare || (tipPreset.value === 'None' && tip?.amount === 0);
      })?.value;
  const currencySymbol = getCurrencySymbol(currency);
  const templatesDemoExperimentEnabled = experiments.enabled('specs.restaurants.templatesDemo');
  const isTemplate = siteIsTemplate && templatesDemoExperimentEnabled;
  const expressCheckoutEnabled = experiments.enabled('specs.restaurants.express-checkout');

  if (isSubmitting && step !== 'payments') {
    setIsSubmitting(false);
  }

  useEffect(() => {
    if (done && !isMobile) {
      scroller.scrollTo('address-information', {
        smooth: 'easeInOutCubic',
        duration: 200,
        offset: -calculateTopValue(0),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [done]);

  function paymentInfoBI() {
    biLogger.report?.(
      paymentsInformationContinue({
        currency,
        paymentType: paymentMethod ?? '',
        totalTip: Math.round(tipCharge),
        totalOrderValue: Math.round(totalOrderPrice),
        isDefaultTip: defaultTipValueSelected,
        isCustomTip: tip?.isCustom,
        tip_type: tip?.tipType,
        tipValue1: getTipValue(selectedTipValue, currencySymbol, tip),
      }),
    );
  }

  useEffect(() => {
    if (applePayAuthorized && paymentMethod === APPLE_PAY_PAYMENT_METHOD_ID && (orderResponse || isSubmitOrderFailed)) {
      submitOrderFinished.current = { finished: true, withError: isSubmitOrderFailed };
    }
  }, [applePayAuthorized, paymentMethod, orderResponse, isSubmitOrderFailed]);

  async function paymentAuthorized(arg: PaymentAuthorizedArgs) {
    setApplePayAuthorized(true);
    setPendingCashierPayment({
      payment: { paymentMethod: APPLE_PAY_PAYMENT_METHOD_ID },
      paymentMethodTitle: APPLE_PAY_PAYMENT_METHOD_TITLE,
    });
    setKeepCashierOpen(false);
    setIsSubmitting(true);
    setCashierPayment({ paymentDetailsId: arg.detailsId ?? '' });
    return submitApplepayPayment();
  }

  const PAYMENT_TIMEOUT = 30;
  const submitApplepayPayment = async () => {
    const hasPolicies = orderPolicy && (orderPolicy.policyCheckbox || orderPolicy.privacyPolicy || orderPolicy.termsAndConditions);
    const skipReviewStep = !hasPolicies;
    onSubmit(skipReviewStep);
    if (skipReviewStep) {
      await waitUntil(() => submitOrderFinished.current.finished, PAYMENT_TIMEOUT);
    }
    paymentInfoBI();
    return !submitOrderFinished.current.withError && submitOrderFinished.current.finished;
  };

  function paymentContinue() {
    setIsSubmitting(true);
    !isTemplate
      ? validateAndInitializePayment(
          (initializePaymentResult) => {
            paymentInfoBI();
            setCashierPayment(convertToPayload(initializePaymentResult));
            onSubmit();
          },
          (error) => {
            biLogger.report?.(
              paymentsInformationContinueValidationError({
                errorReason: convertCashierErrorToString(error),
              }),
            );
            setCashierPaymentDone();
            setIsSubmitting(false);
            scroller.scrollTo('checkout-payments', {
              smooth: 'easeInOutCubic',
              duration: 200,
              offset: -calculateTopValue(0) - 36,
            });
          },
        )
      : onSubmit();
  }

  // There's an issue with the Cashier widget not updating addresses, so we must re-render it on each address update
  const renderCashier = step !== 'address-information' && !isTemplate;

  const titleId = `${dataHooks.checkoutPayments}-title`;
  const amount = getDisplayablePrice(totalOrderPrice, locale, currency).match(/[\d.,]+/g)?.[0] || '';
  return (
    <Scroller name="checkout-payments" condition={isMobile && !collapsed && !done}>
      <div
        className={styles.wrapper}
        data-hook={dataHooks.checkoutPayments}
        aria-labelledby={titleId}
        aria-describedby={describedby}
      >
        <CheckoutFlowStepTitle
          text={t('checkout_main_payments_title')}
          done={done}
          collapsed={collapsed}
          index={index}
          onEdit={onEdit}
          editButtonDataHook={dataHooks.checkoutSummaryLineEditPayment}
          titleId={titleId}
        />
        {shouldDisplayTip && !done && !collapsed && (
          <TipPicker tip={tip} onTipChange={setTip} currency={currency} locale={locale} tipPresets={tipPresets} />
        )}
        {expressCheckoutEnabled && (
          <CheckoutExpress hidden={!keepCashierOpen && (done || collapsed)} onPaymentAuthorized={paymentAuthorized} />
        )}
        {renderCashier && (
          <Cashier
            hidden={!keepCashierOpen && (done || collapsed)}
            paymentMethodChanged={(paymentMethodId, paymentMethodTitle) => {
              setPendingCashierPayment({ payment: { paymentMethod: paymentMethodId }, paymentMethodTitle });
              setApplePayAuthorized(false);
            }}
            amount={amount}
            onPaymentStart={(method: string) => {
              setKeepCashierOpen(true);
            }}
            onPaymentComplete={(method: string, result: any) => {
              setKeepCashierOpen(false);
            }}
          />
        )}

        {!done && !collapsed && <div className={styles.padder} />}
        {!done && !collapsed && (
          <Button
            autoFocus
            upgrade
            fullWidth
            priority={PRIORITY.primary}
            className={styles.button}
            data-hook={dataHooks.checkoutPaymentsContinue}
            onClick={paymentContinue}
            loading={isSubmitting || isLoading}
          >
            <Text typography="p2-m-colorless">{t('checkout_main_button_continue')}</Text>
          </Button>
        )}

        {done && <CheckoutPaymentsSummary payments={payments} />}
      </div>
    </Scroller>
  );
};

CheckoutPayments.displayName = 'CheckoutPayments';

export default CheckoutPayments;
