import Modal from './Modal';
import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';

function mapStateToProps(state: AppState, ownProps: any) {
  return {
    isMobile: state.platformParams.isMobile,
    compClassName: state.platformParams.compClassName,
    ...ownProps,
  };
}

export default connect(mapStateToProps)(Modal);
